import React, { useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../axiosInstance";
import { API_ROUTES } from "../config/apiRoutes";
import { setUserData } from "../slices/auth-slice";
import Loading from "./Loading";
import AddIcon from "./svgs/AddIcon";
import CancelIcon from "./svgs/CancelIcon";
import { isPremiumUser } from "../helper/isPremium";
import { setShowPremiumModal } from "../slices/premium-slice";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import toast from "react-hot-toast";

const FollowButton = ({
  eventId,
  setEventId = null,
  isProfile = false,
  isPrivate = false,
  removeEvent = null,
  setUserEvents = null,
  fetchPrivateEvents = null,
  isFilterEvent = false,
}) => {
  const [isApiCalling, setIsApiCalling] = useState(false);
  const dispatch = useDispatch();

  const storeUser = useSelector((state) => state.auth.user);
  const userFollowedEvents = useSelector((state) => state.auth.upComingEvents);

  // Determine if the current event is followed by the user
  const isFollowed = useMemo(() => {
    return !!storeUser?.accepted_events?.find((event) => event._id === eventId);
  }, [storeUser, eventId]);

  // Check if user has premium status
  const isPremium = useMemo(() => isPremiumUser(storeUser), [storeUser]);
  // console.log("storeUser : ", storeUser);

  const handleFollowClick = useCallback(
    async (e) => {
      e.stopPropagation();
      setIsApiCalling(true);
      let response = null;

      try {
        if (isPrivate) {
          // Delete event logic for private events
          response = await axiosInstance.post(API_ROUTES.DELETE_EVENT, {
            id: eventId,
          });
          fetchPrivateEvents();
          const user = JSON.parse(localStorage.getItem("user" || "{}"));

          localStorage.setItem("user", JSON.stringify(user));
          dispatch(setUserData(user));

          // For private events, don't update localStorage or perform other updates
        } else {
          // Handle non-private events
          if (isFollowed) {
            // Unfollow logic for non-private events
            response = await axiosInstance.post(API_ROUTES.UN_FOLLOW_EVENT, {
              id: eventId,
            });
            toast.success("Event unfollowed successfully");
          } else {
            // Follow logic with premium check
            if (!isPremium && userFollowedEvents?.length > 6) {
              dispatch(setShowPremiumModal(true));
            } else {
              response = await axiosInstance.post(API_ROUTES.FOLLOW_EVENT, {
                id: eventId,
              });
              toast.success("Event followed successfully");
            }
          }

          // Only update localStorage and Redux for non-private events
          if (response) {
            localStorage.setItem("user", JSON.stringify(response.data.user));
            dispatch(setUserData(response.data.user));

            // Handle additional updates based on component context
            if (setUserEvents && isFilterEvent) {
              let updatedUser = response.data.user;
              let updatedEvents = updatedUser?.accepted_events || [];

              if (isProfile) {
                removeEvent?.(eventId);
              } else {
                // Sort events by date
                updatedEvents.sort(
                  (a, b) => new Date(a.start_date) - new Date(b.start_date)
                );
              }

              setUserEvents(updatedEvents);
            }

            // Close modal if setEventId is provided
            if (setEventId) {
              setEventId(null);
            }
          }
        }
      } catch (error) {
        console.error("Error handling event action:", error);
      } finally {
        setIsApiCalling(false);
      }
    },
    [
      eventId,
      isFollowed,
      isPremium,
      isPrivate,
      userFollowedEvents,
      dispatch,
      setUserEvents,
      isFilterEvent,
      isProfile,
      removeEvent,
      setEventId,
    ]
  );

  return (
    <div style={{ position: "relative" }}>
      {isApiCalling && <Loading />}
      <span onClick={handleFollowClick} style={{ cursor: "pointer" }}>
        <SwitchTransition>
          <CSSTransition
            // change the key based on the icon we want to show
            key={isPrivate || isFollowed ? "cancel" : "add"}
            // match this with the CSS classes below
            classNames="icon"
            // Tells React when the transition is done
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
          >
            {isPrivate || isFollowed ? <CancelIcon /> : <AddIcon />}
          </CSSTransition>
        </SwitchTransition>
      </span>
    </div>
  );
};

export default FollowButton;
