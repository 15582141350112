import Movies from "./Movies.png";
import TVShows from "./TVShows.png";
import Festivals from "./Festivals.png";
import Music from "./Music.png";
import Sport from "./Sport.png";
import Tech from "./Tech.png";
import All from "./All.png";
import Gift from "./Gift.png";
import Upcoming from "./upcomingImage.png";
import lifeStyle from "./lifeStyle.png";

export const images = {
  All: All,
  Upcoming: Upcoming,
  Movies: Movies,
  Holidays: Gift,
  Sport: Sport,
  Lifestyle: lifeStyle,
  Festivals: Festivals,
  TVShows: TVShows,
  Music: Music,
  TechGames: Tech,
};
