import React from "react";

const ErrorMessage = ({ id, children }) => {
  return (
    <div className="my-1">
      <p
        id={`${id}-error`}
        data-testid={`${id}-error`}
        className="error-text"
      >
        {children}
      </p>
    </div>
  );
};

export default ErrorMessage;
