import * as yup from "yup";

export const signupSchema = yup.object().shape({
  full_name: yup
    .string()
    .min(4, "Full name must be at least 4 characters long.")
    .max(20, "Full name cannot be longer than 20 characters.")
    .required("Full name is required."),

  username: yup
    .string()
    .min(4, "username must be at least 4 characters long.")
    .max(20, "username name cannot be longer than 20 characters.")
    .required("username is required."),

  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Email is required."),

  password: yup
    .string()
    .min(4, "Password must be at least 4 characters long.")
    .max(20, "Password cannot be longer than 20 characters.")
    .required("Password is required."),

  confirmPassword: yup
    .string()
    .min(4, "Password must be at least 4 characters long.")
    .max(20, "Password cannot be longer than 20 characters.")
    .required("Please confirm your password.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Email is required."),

  password: yup
    .string()
    .min(4, "Password must be at least 4 characters long.")
    .max(20, "Password cannot be longer than 20 characters.")
    .required("Password is required."),
});

export const requestOtpSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Email is required."),
});

export const resetPasswordSchema = yup.object().shape({
  code: yup.string().required("OTP Code is required."),

  password: yup
    .string()
    .min(4, "Password must be at least 4 characters long.")
    .max(20, "Password cannot be longer than 20 characters.")
    .required("Password is required."),

  confirmPassword: yup
    .string()
    .min(4, "Password must be at least 4 characters long.")
    .max(20, "Password cannot be longer than 20 characters.")
    .required("Please confirm your password.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

export const userSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Email is required."),

  currentPassword: yup
    .string()
    .test("conditional-current-password", function (value) {
      const { newPassword, confirmPassword, email } = this.parent;

      // If either new password or confirm password is entered
      if (newPassword || confirmPassword || email) {
        // Check if value exists
        if (!value) {
          return this.createError({ message: "Current password is required." });
        }
        // Check minimum length
        if (value.length < 4) {
          return this.createError({
            message: "Current password must be at least 4 characters long.",
          });
        }
        // Check maximum length
        if (value.length > 20) {
          return this.createError({
            message: "Current password cannot be longer than 20 characters.",
          });
        }
      }
      return true; // No validation if no new passwords are entered
    }),

  newPassword: yup.string().test("conditional-new-password", function (value) {
    const { confirmPassword } = this.parent;

    // If either new password or confirm password is entered
    if (value || confirmPassword) {
      // Check if value exists
      if (!value) {
        return this.createError({ message: "New password is required." });
      }
      // Check minimum length
      if (value.length < 4) {
        return this.createError({
          message: "New password must be at least 4 characters long.",
        });
      }
      // Check maximum length
      if (value.length > 20) {
        return this.createError({
          message: "New password cannot be longer than 20 characters.",
        });
      }
    }
    return true; // No validation if both fields are empty
  }),

  confirmPassword: yup
    .string()
    .test("conditional-confirm-password", function (value) {
      const { newPassword } = this.parent;

      // If either new password or confirm password is entered
      if (value || newPassword) {
        // Check if value exists
        if (!value) {
          return this.createError({ message: "Confirm password is required." });
        }
        // Check minimum length
        if (value.length < 4) {
          return this.createError({
            message: "Confirm password must be at least 4 characters long.",
          });
        }
        // Check maximum length
        if (value.length > 20) {
          return this.createError({
            message: "Confirm password cannot be longer than 20 characters.",
          });
        }
        // Check if passwords match
        if (value !== newPassword) {
          return this.createError({ message: "Passwords must match." });
        }
      }
      return true; // No validation if both fields are empty
    }),
});

export const updateUserSchema = yup.object().shape({
  name: yup.string().required("Name is required."),

  bio: yup.string(),

  username: yup
    .string()
    .min(4, "username must be at least 4 characters long.")
    .max(20, "username name cannot be longer than 20 characters.")
    .required("username is required."),

  isPublic: yup.boolean(),
});

export const verifyOtpSchema = yup.object().shape({
  code: yup.string().required("OTP Code is required."),
});
