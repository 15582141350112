import { yupResolver } from "@hookform/resolvers/yup";
import AppleImage from "../images/apple-icon.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loginSchema } from "../validation/yupValidation";
import axiosInstance from "../axiosInstance";
import { API_ROUTES } from "../config/apiRoutes";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import InputField from "../common/InputField";
import { useEffect } from "react";
import AppleLogin from "react-apple-login";
import { useDispatch } from "react-redux";
import { login } from "../slices/auth-slice";
import { GoogleLogin } from "@react-oauth/google";

function Signin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleGoogleSuccess = async (credentialResponse) => {
    if (!credentialResponse || !credentialResponse.credential) {
      toast.error("Google sign-in failed.");
      return;
    }

    try {
      const response = await axiosInstance.post(API_ROUTES.LOGIN_GOOGLE, {
        token: credentialResponse.credential,
      });

      if (response?.data) {
        const { user, token } = response.data;

        if (!user || !user.email || !user.username) {
          toast.error("Invalid user data received. Please try again.");
          return;
        }

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        dispatch(login({ user, token }));

        toast.success("Welcome back!");

        const redirect = searchParams.get("redirect");
        navigate(redirect ? redirect : "/");
      }
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      toast.error("Failed to sign in with Google. Please try again.");
    }
  };
  // Apple Login Handlers
  const handleResponse = async (response) => {
    if (!response) {
      console.error("No response received from Apple Login");
      return;
    }
    console.log("Apple Login Response:", JSON.stringify(response, null, 2));

    // Extract token or other required fields from the Apple login response
    const { authorization: { id_token: appleToken } = {} } = response;
    const { authorization: { code: appleCode } = {} } = response;
    console.log("appleToken", appleToken);
    console.log("appleCode", appleCode);
    if (!appleToken) {
      toast.error("Failed to retrieve Apple token.");
      return;
    }

    // Prepare the payload for the API request
    const payload = {
      session_id: appleCode,
      id_token: appleToken,
    };

    try {
      // Make the API call to your backend
      console.log("Request Payload:", payload);
      const apiResponse = await axiosInstance.post(
        API_ROUTES.LOGIN_APPLE,
        payload
      );

      if (apiResponse?.data) {
        console.log("API Response:", apiResponse.data);
        // Store user and token in localStorage
        localStorage.setItem("user", JSON.stringify(apiResponse.data.user));
        localStorage.setItem("token", apiResponse.data.token);
        const { user, token } = response.data;
        dispatch(login({ user, token }));
        // localStorage.removeItem("sessionId");
        toast.success("Welcome to LFT!");
        const redirect = searchParams.get("redirect");
        if (redirect) {
          navigate(redirect); // Redirect to the homepage or dashboard
        } else {
          navigate("/"); // Redirect to the homepage or dashboard
        }
      }
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      toast.error("Failed to log in with Apple. Please try again.");
    }
  };

  const handleError = (error) => {
    console.error("Apple Login Error:", error);
    toast.error("Apple Login failed. Please try again.");
  };

  // Cleanup user session on mount
  useEffect(() => {
    // localStorage.removeItem("user");
    // localStorage.removeItem("token");
  }, []);

  // React Hook Form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onSubmit",
  });

  // Email/Password Login Handler
  const onSubmit = async (formData) => {
    const payload = {
      email: formData.email,
      password: formData.password,
    };

    try {
      const response = await axiosInstance.post(
        API_ROUTES.LOGIN_EMAIL,
        payload
      );
      if (response?.data) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", response.data.token);
        const { user, token } = response.data;
        dispatch(login({ user, token }));
        // localStorage.removeItem("sessionId");
        toast.success("Welcome back!");
        const redirect = searchParams.get("redirect");
        if (redirect) {
          navigate(redirect); // Redirect to the homepage or dashboard
        } else {
          navigate("/"); // Redirect to the homepage or dashboard
        }
      }
    } catch (error) {
      console.error("Login Error:", error);
      toast.error("Failed to sign in. Please check your credentials.");
    }
  };

  return (
    <div className="auth-scree-wrapper">
      <div className="auth-scree-inner">
        <div className="auth-main-box">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Sign in</h2>
            <div className="site-input">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder="Enter Email"
                    type="text"
                    {...field}
                    errors={errors.email}
                  />
                )}
              />
            </div>
            <div className="site-input">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder="Enter Password"
                    type="password"
                    {...field}
                    PasswordIcon={true}
                    errors={errors.password}
                  />
                )}
              />
            </div>
            <div className="site-input">
              <a
                style={{ cursor: "pointer" }}
                className="simple-link-text"
                onClick={() => navigate("/reset-password")}
              >
                Forgot your password?
              </a>
            </div>
            <div className="site-input">
              <button type="submit" className="site-btn">
                Continue
              </button>
            </div>
            <div className="site-input">
              <span className="or-gap">Or</span>
            </div>
            <div className="site-input">
              {/* Styled Button Wrapper */}
              <div className="third-party-btn google-btn">
                <img
                  src="https://developers.google.com/identity/images/g-logo.png"
                  alt="Google"
                  style={{ marginRight: "10px", height: "20px" }}
                />
                Sign in with Google
                {/* Overlay Google Login Button */}
                <div className="google-login-overlay">
                  <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={() =>
                      toast.error("Google login failed. Please try again.")
                    }
                    text="signin_with"
                  />
                </div>
              </div>
            </div>
            <AppleLogin
              clientId="com.lft.web" // Replace with your actual clientId
              redirectURI="https://lookingforwardto.com" // Ensure it matches Apple Developer setup
              responseType="code id_token"
              responseMode="form_post"
              usePopup={true}
              callback={handleResponse}
              onError={handleError}
              className="third-party-btn"
              render={(props) => (
                <button onClick={props.onClick} className="third-party-btn">
                  <img
                    src={AppleImage}
                    alt="Apple"
                    style={{ marginRight: "10px" }}
                  />
                  Sign in with Apple
                </button>
              )}
            />
          </form>
        </div>
        <div className="anchor-link-bottom">
          Don’t have an account?{" "}
          <a className="text-underline">
            <p
              onClick={() => navigate("/signup")}
              style={{ cursor: "pointer" }}
            >
              Sign up
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Signin;
