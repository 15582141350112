import { hookstate, useHookstate } from "@hookstate/core";

const initialState = hookstate({
  storedPublicEvents: [],
  categoriesList: [],
});

export const useGlobalState = () => {
  const state = useHookstate(initialState);

  return {
    getEvents: () => state.storedPublicEvents.value,
    getCategories: () => state.categoriesList.value,
    setPublicEvents: (events) => {
      state.storedPublicEvents.set(events);
    },
    removePublicEvents: () => {
      state.storedPublicEvents.set([])
    },
    setCategoriesList: (categories) => {
      state.categoriesList.set(categories);
    },
    removeCategories: () => {
      state.categoriesList.set([])
    },
  };
};
