import React, { useEffect, useMemo, useRef, useState } from "react";
import axiosInstance from "../axiosInstance";
import { API_ROUTES } from "../config/apiRoutes";
import {
  feedTab,
  getUpcomingDate,
  INNER_TAB_NAMES,
  loginGuest,
  calculateNextEventDate,
  processEventData,
  TAB_NAMES,
} from "../helper";
import CardSkeleton from "../common/EventSkeleton.jsx";
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Categories from "./Categories";
import FeedsList from "./FeedsList";
import NewsFeed from "./NewsFeed";
import TabsComponent from "../common/TabsComponent";
import { EVENT_PAGE_SIZE, LOGIN_TYPE } from "../constant";
import { useGlobalState } from "../store/store";
import WelcomeModal from "../common/landing/WelcomeModal";
import GoPremimumBanner from "../common/banner/GoPremiumBanner";
import ModalCustom from "../common/ModalCustom";
import { useDispatch, useSelector } from "react-redux";
import { setShowPremiumModal } from "../slices/premium-slice";
import { login, setUserData, setWelcomeModalShown } from "../slices/auth-slice";
import RecentPosts from "./RecentPost";
import CreateEvent from "./CreateEvent";
import UpdateEvent from "./UpdateEvent";
import { Helmet } from "react-helmet-async";
import ProfileView from "./ProfileView";
const DiscoverFeed = ({ isAuthenticated, isPreimum }) => {
  const state = useGlobalState();
  const allEvents = state.getEvents({ noproxy: true }) || [];
  const categories = state.getCategories({ noproxy: true }) || [];
  const user = JSON.parse(localStorage.getItem("user" || "{}"));
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [publicEvents, setPublicEvents] = useState([]);
  const [eventId, setEventId] = useState(null);
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingEvents, setIsFetchingEvents] = useState(false);
  const [openNewsFeed, setOpenNewsFeed] = useState(false);
  const [activeTab, setActiveTab] = useState("Discover");
  const [innerTab, setInnerTab] = useState("");
  const [eventTitleBox, setEventTitleBox] = useState({});
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const currentPage = useRef(1);
  const [innerTabPage, setInnerTabPage] = useState(1);
  const [containerHeight, setContainerHeight] = useState(EVENT_PAGE_SIZE);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.token); // Get authToken from Redux
  const [privateUserEvents, setPrivateUserEvents] = useState([]);
  const [videos, setVideos] = useState([]);
  const [show, setShow] = useState(false);
  const [planShow, setPlanShow] = useState(false);
  const showPremiumModal = useSelector(
    (state) => state.premium.showPremiumModal
  );

  const { eventId: routeEventId, innerTab: routeInnerTab } = useParams();
  const location = useLocation();

  const handleOpenCreateEventModal = () => {
    setShowCreateEventModal(true);
  };

  const handleCloseCreateEventModal = () => {
    setShowCreateEventModal(false);
  };

  const handleEventCreated = (newEvent) => {
    setPrivateUserEvents((prevEvents) => [...prevEvents, newEvent]);
    handleCloseCreateEventModal();
  };

  useEffect(() => {
    if (isAuthenticated) {
    }
  }, [isAuthenticated, authToken]);

  useEffect(() => {
    if (routeEventId) {
      setEventId(routeEventId);
      if (routeInnerTab === "news") {
        setInnerTab(INNER_TAB_NAMES.feed);
        setOpenNewsFeed(true);
      }
    } else {
      setEventId(null);
      setOpenNewsFeed(false);
      setEventTitleBox({});
    }
  }, [routeEventId, routeInnerTab]);

  useEffect(() => {
    if (eventId) {
      fetchEventNews(eventId);
      if (location.pathname.includes(`/event/${eventId}`)) {
        setOpenNewsFeed(true);
      }
    } else {
      setOpenNewsFeed(false);
      setEventTitleBox({});
    }
  }, [eventId, location.pathname]);

  useEffect(() => {
    if (!eventId && !openNewsFeed && !location.pathname.startsWith("/event/")) {
      const currentUrl = new URL(window.location.href);
      if (currentUrl.pathname !== "/") {
        navigate("/", { replace: true });
      }
    }
  }, [eventId, openNewsFeed, navigate, location.pathname]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const fetchEventNews = async (eventId) => {
    setIsLoading(true);
    try {
      const newsResponse = await axiosInstance.post(API_ROUTES.EVENT_NEWS, {
        event_id: eventId,
      });

      if (newsResponse && newsResponse.data && newsResponse.data.news) {
        setEvent(newsResponse.data.news);
        setVideos(newsResponse.data.event.videos);
      } else {
        setEvent(null);
      }

      const publicEventsResponse = await axiosInstance.post(
        API_ROUTES.PUBLIC_EVENTS
      );
      if (publicEventsResponse && publicEventsResponse.data) {
        const eventDetails = publicEventsResponse.data.find(
          (event) => event._id === eventId
        );
        if (eventDetails) {
          setEventTitleBox(eventDetails);
        }
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
      setEvent(null);
    } finally {
      setIsLoading(false);
    }
  };
  const getPublicEvents = async () => {
    try {
      setIsFetchingEvents(true);
      const publicEventsResponse = await axiosInstance.post(
        API_ROUTES.PUBLIC_EVENTS
      );

      if (publicEventsResponse?.data) {
        const noDateEvents = publicEventsResponse.data.filter(
          (event) =>
            calculateNextEventDate(event?.start_date, event?.repeat_days) ===
            null
        );

        const futureEvents = publicEventsResponse.data.filter((event) => {
          const startDate = calculateNextEventDate(
            event?.start_date,
            event?.repeat_days
          );
          return startDate > new Date();
        });

        // Combine and sort events
        let allEvents = [...futureEvents, ...noDateEvents].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        let tallCount = 0;
        allEvents = allEvents.map((event, index) => {
          if (index < 16 && event.post_shape === 3) {
            tallCount++;
          }
          return {
            ...event,
            upcoming: getUpcomingDate(event?.start_date, event?.repeat_days),
            duration: processEventData(event),
          };
        });

        state.setPublicEvents(allEvents);
        currentPage.current = 1;
        setPublicEvents(allEvents);

        if (allEvents.length > 15 || tallCount > 0) {
          setShowMore(true);
          setContainerHeight(EVENT_PAGE_SIZE);
        }
      }
    } catch (error) {
      console.error("Error fetching public events:", error);
    } finally {
      setIsFetchingEvents(false);
    }
  };

  const loginCallBack = (user, token) => {
    dispatch(login({ user, token }));
  };

  const getCategories = async () => {
    try {
      const CATEGORY = { name: "All", id: 398 };
      const response = await axiosInstance.post(API_ROUTES.CATEGORIES);

      if (response?.data) {
        await loginGuest(loginCallBack);
        await getPublicEvents();

        const allCategories = [CATEGORY, ...response.data].map((category) => ({
          ...category,
          iconName: category.name.replace("&", "").replace(/ /g, ""),
        }));

        state.setCategoriesList(allCategories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const handleFollow = async (eventId, isCurrentlyFollowed) => {
    setIsFetchingEvents(true);
    try {
      const apiRoute = isCurrentlyFollowed
        ? API_ROUTES.UN_FOLLOW_EVENT
        : API_ROUTES.FOLLOW_EVENT;
      const response = await axiosInstance.post(apiRoute, { id: eventId });

      if (response?.data?.user) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        // Update publicEvents to reflect the new follow status
        setPublicEvents((prevEvents) =>
          prevEvents.map((event) =>
            event._id === eventId
              ? { ...event, isFollowed: !isCurrentlyFollowed }
              : event
          )
        );
      }
    } catch (error) {
      console.error("Error following/unfollowing event:", error);
    } finally {
      setIsFetchingEvents(false);
    }
  };
  const welcomeModalshown = useSelector(
    (state) => state.auth.welcomeModalshown
  );
  useEffect(() => {
    setPublicEvents([]);
    getCategories();
    // eslint-disable-next-line
  }, [user?._id]);

  // Define the async function inside
  const fetchPrivateEvents = async () => {
    try {
      const privateEventsResponse = await axiosInstance.post(
        API_ROUTES.PRIVATE_EVENTS
      );

      if (privateEventsResponse?.data) {
        setPrivateUserEvents(privateEventsResponse?.data);
      }
    } catch (error) {
      console.error("Error fetching private events:", error);
    }
  };

  useEffect(() => {
    // Skip the effect if user or accepted_events is undefined
    if (!user || !user.accepted_events) return;

    // Call the async function
    fetchPrivateEvents();

    // Cleanup function
    return () => {
      // Cleanup code here if necessary
    };

    // Use JSON.stringify to ensure deep comparison of the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user?.accepted_events)]);

  const userEvents = useMemo(() => {
    // const acceptedEvents = user?.accepted_events || [];
    const acceptedEvents = (user?.accepted_events || []).concat(
      privateUserEvents
    );
    // return acceptedEvents
    //   ?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
    //   .map((sortData) => ({
    //     ...sortData,
    //     upcoming: getUpcomingDate(sortData.start_date, sortData.repeat_days),
    //     duration: processEventData(sortData),
    //   }));
    const noDateEvents = acceptedEvents?.filter(
      (event) =>
        calculateNextEventDate(event?.start_date, event?.repeat_days) === null
    );

    const futureEvents = acceptedEvents?.filter((event) => {
      const startDate = calculateNextEventDate(
        event?.start_date,
        event?.repeat_days
      );
      return startDate > new Date();
    });
    // Combine and sort events
    let allEvents = [...futureEvents, ...noDateEvents];
    allEvents.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return (allEvents = allEvents.map((sortData) => {
      //	debugger; // Pause execution here
      return {
        ...sortData,
        upcoming: getUpcomingDate(sortData?.start_date, sortData?.repeat_days),
        duration: processEventData(sortData),
      };
    }));
    // eslint-disable-next-line
  }, [user?.accepted_events]);

  const pastEvents = useMemo(() => {
    const currentDate = moment();

    const acceptedEvents = user?.accepted_events || [];

    const events = acceptedEvents.filter((event) => {
      const eventDate = moment(event?.start_date);

      return (
        eventDate.isBefore(currentDate, "day") && // Check if event is in the past
        event?.repeat_days === 0 && // Ensure event is not repeated
        event?.start_date != null // Ensure start_date is not null
      );
    });

    return events
      .sort((a, b) =>
        moment(a?.start_date).isBefore(moment(b?.start_date)) ? -1 : 1
      ) // Sort events by start_date
      .map((event) => ({
        ...event, // Spread the existing event properties
        duration: processEventData(event), // Add the duration property
      }));
  }, [user?.accepted_events]);

  const handleInnerTab = (tabName) => {
    setInnerTab(tabName);
  };
  const handleCloseInternal = () => {
    navigate("/", { replace: true });
    setInnerTab("");
    setOpenNewsFeed(false);
    setEventId(null);
    const currentUrl = new URL(window.location.href);
    currentUrl.pathname = "/";
    window.history.replaceState({}, document.title, currentUrl.toString());
  };
  const navigateToHome = () => {
    setEventId(null); // Clear eventId before navigating
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (routeEventId) {
      setEventId(routeEventId);
      if (routeInnerTab === "news") {
        setInnerTab(INNER_TAB_NAMES.feed);
        setOpenNewsFeed(true);
      }
    } else {
      setEventId(null);
      setOpenNewsFeed(false);
      setEventTitleBox({});
    }
  }, [routeEventId, routeInnerTab]);

  useEffect(() => {
    if (eventId) {
      fetchEventNews(eventId);
      if (location.pathname.includes(`/event/${eventId}`)) {
        setOpenNewsFeed(true);
      }
    } else {
      setOpenNewsFeed(false);
      setEventTitleBox({});
    }
  }, [eventId, location.pathname]);

  useEffect(() => {
    if (!eventId && !openNewsFeed && !location.pathname.startsWith("/event/")) {
      const currentUrl = new URL(window.location.href);
      if (currentUrl.pathname !== "/") {
        navigate("/", { replace: true });
      }
    }
  }, [eventId, openNewsFeed, navigate, location.pathname]);

  const loadUserData = async () => {
    const res = await axiosInstance.post(API_ROUTES.GET_USER_DATA);
    const { data = {} } = res || {};
    if (data.user) {
      dispatch(setUserData(res.data.user));
      localStorage.setItem("user", JSON.stringify(res.data.user));
    }
  };
  useEffect(() => {
    loadUserData();
    if (!isAuthenticated) {
      setShowWelcomeModal(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setInnerTabPage(1);
    currentPage.current = 1;
    let flag = false;
    if (activeTab === TAB_NAMES.Looking) {
      if (innerTab === INNER_TAB_NAMES.feed || innerTab === "") {
        flag = userEvents.length > 15;
      } else if (innerTab === INNER_TAB_NAMES.username) {
        flag = pastEvents.length > 15;
      }
    } else {
      flag = publicEvents.length > 15;
    }
    setShowMore(flag);
    setContainerHeight(flag ? EVENT_PAGE_SIZE : undefined);
  }, [activeTab, innerTab]);
  const toggleWelcomeModal = () => {
    //setShowWelcomeModal(!showWelcomeModal);
    dispatch(setWelcomeModalShown(true));
  };
  const handleShowMore = () => {
    const eventContainer = document.getElementById("event-container");
    const containerHeight = eventContainer?.clientHeight;
    if (activeTab === TAB_NAMES.Looking) {
      const currPage = innerTabPage + 1;
      setInnerTabPage(currPage);
      const currHeight = EVENT_PAGE_SIZE * currPage;
      setContainerHeight(currHeight);
      if (currHeight < containerHeight) {
        setShowMore(true);
      } else {
        setShowMore(false);
        setContainerHeight(undefined);
      }
    } else {
      currentPage.current++;
      const currHeight = EVENT_PAGE_SIZE * currentPage.current;
      setContainerHeight(currHeight);

      // Load more events
      const allEvents = state.getEvents({ noproxy: true }) || [];
      const nextEvents = allEvents.slice(
        15 * currentPage.current,
        15 * (currentPage.current + 1)
      );

      // setPublicEvents((prevEvents) => [...prevEvents, ...nextEvents]);

      // Check if there are more events to load
      if (nextEvents.length > 0) {
        setShowMore(true);
      } else {
        setShowMore(false);
        setContainerHeight(undefined);
      }
    }
  };
  const filterByCategory = (categoryName) => {
    setSelectedCategory(categoryName);
    let filteredCategory = [];
    if (categoryName === "All") {
      filteredCategory = allEvents;
    } else if (categoryName === "Upcoming") {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const thirtyDaysLater = new Date();
      thirtyDaysLater.setDate(currentDate.getDate() + 30);
      thirtyDaysLater.setHours(23, 59, 59, 999);

      const upcoming = allEvents.filter((event) => {
        const eventDate = new Date(event.upcoming);
        eventDate.setHours(0, 0, 0, 0);
        return eventDate >= currentDate && eventDate <= thirtyDaysLater;
      });

      filteredCategory = upcoming.sort(
        (a, b) => new Date(a?.start_date) - new Date(b?.start_date)
      );
    } else {
      filteredCategory = allEvents.filter(
        (event) => event.category.name === categoryName
      );
    }
    // const eventChunks = chunkArray(filteredCategory, 2);
    currentPage.current = 1;
    if (filteredCategory.length > 16) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
    setPublicEvents(filteredCategory);
  };
  const handleSubscription = () => {
    dispatch(setShowPremiumModal(false));
    // navigate("/profile/?tab=plan");
    setShow(true);
    setPlanShow(true);
  };
  return (
    <>
      <div className="discover-wrapper">
        <Helmet>
          <title>lft - looking forward to</title>
          <meta
            name="description"
            content="Discover what's next. Movies, shows, games, and more to look forward to."
          />
          <meta property="og:title" content="lft-looking forward to" />
          <meta
            property="og:description"
            content="Discover what's next. Movies, shows, games, and more to look forward to."
          />
        </Helmet>
        <div className="container">
          <div className="site-heading">
            <h1>
              Discover what’s <span className="clr-primary">next.</span>
            </h1>
            <h2>
              Movies, shows, games, and more to <br />
              <span className="clr-primary">look forward to.</span>
            </h2>
          </div>

          <div className="custom-tabs-wrapper" style={{ position: "relative" }}>
            <ModalCustom
              show={showPremiumModal}
              handleClose={() => dispatch(setShowPremiumModal(false))}
              title="Upgrade to add more events"
              modalUpgrade="upgrade-modal"
              modalBody="custom-modal-body"
              modalTitle="modal-title"
            >
              <div className="d-flex flex-column align-items-center upgrade-modal">
                <button
                  onClick={() => dispatch(setShowPremiumModal(false))}
                  className="close-button"
                  aria-label="Close"
                >
                  ✕
                </button>
                <p className="text-center upgrade-modal-text">Free accounts can add up to 7 events. Upgrade to add unlimited events.</p>
                <button className="outline-button" onClick={handleSubscription}>
                  Upgrade - Starting at $2/mo
                </button>
              </div>
            </ModalCustom>
            <TabsComponent
              tabs={feedTab}
              activeTab={activeTab}
              onTabChange={handleTabChange}
            />

            {activeTab === TAB_NAMES.Discover && (
              <>
                <ul
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: "16px",
                    listStyle: "none",
                    paddingTop: "15px",
                    paddingLeft: "0px",
                  }}
                >
                  <Categories
                    filterByCategory={filterByCategory}
                    categories={categories}
                    selectedCategory={selectedCategory}
                  />
                </ul>
                <div
                  style={
                    containerHeight
                      ? { height: `${containerHeight}px`, overflow: "hidden" }
                      : undefined
                  }
                >
                  {isFetchingEvents ? (
                    <CardSkeleton />
                  ) : (
                    <FeedsList
                      events={publicEvents}
                      setEvent={setEvent}
                      setEventId={setEventId}
                      setEventTitleBox={setEventTitleBox}
                      setIsLoading={setIsLoading}
                      isFilterEvent={false}
                      drag={false}
                      handleFollow={handleFollow} // Pass the handleFollow function
                    />
                  )}
                </div>
              </>
            )}

            {activeTab === TAB_NAMES.Looking && (
              <div>
                {userEvents.length > 0 ? ( // Check if userEvents has any items
                  <div>
                    <ul
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "16px",
                        listStyle: "none",
                        paddingTop: "15px",
                        justifyContent: "center",
                      }}
                    >
                      {LOGIN_TYPE.includes(user?.login_type) ? (
                        <li
                          className="category tags discover-tab-border"
                          onClick={() => navigate(user?.username)}
                        >
                          lf.to/
                          <span className="text-theme-color">
                            {user?.username}
                          </span>
                        </li>
                      ) : (
                        <li
                          className="category tags discover-tab-border"
                          onClick={() => navigate("signin")}
                        >
                          share your{" "}
                          <span className="clr-primary mx-1"> lf.to</span> Page
                        </li>
                      )}
                      <li
                        className={`${
                          innerTab === "NewsFeed"
                            ? "activeCategory"
                            : "category"
                        }  tags discover-tab-border`}
                        onClick={() => {
                          setOpenNewsFeed(true);
                          handleInnerTab(
                            innerTab === INNER_TAB_NAMES.feed
                              ? ""
                              : INNER_TAB_NAMES.feed
                          );
                        }}
                      >
                        NewsFeed
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        className={`${
                          innerTab === INNER_TAB_NAMES.past
                            ? "activeCategory"
                            : "category"
                        }  tags discover-tab-border`}
                        onClick={() =>
                          handleInnerTab(
                            innerTab === INNER_TAB_NAMES.past
                              ? ""
                              : INNER_TAB_NAMES.past
                          )
                        }
                      >
                        Past
                      </li>
                      <li
                        className={`${
                          innerTab === INNER_TAB_NAMES.createEvent
                            ? "activeCategory"
                            : "category"
                        } tags discover-tab-border`}
                        onClick={handleOpenCreateEventModal}
                      >
                        {INNER_TAB_NAMES.createEvent}
                      </li>
                    </ul>
                    <ModalCustom
                      show={showCreateEventModal}
                      handleClose={handleCloseCreateEventModal}
                      title="Create Event"
                    >
                      <CreateEvent
                        fetchPrivateEvents={fetchPrivateEvents}
                        onClose={handleCloseCreateEventModal}
                        onEventCreated={handleEventCreated}
                      />
                    </ModalCustom>
                  </div>
                ) : (
                  // Show 'No events found' message if userEvents is empty
                  <div className="blank-tabs-data">
                    <h3>
                      Add events from the Discover feed to see them here and
                      share your <span className="clr-primary">lf.to</span> page
                    </h3>
                  </div>
                )}
                {innerTab !== INNER_TAB_NAMES.past && (
                  <div
                    style={
                      containerHeight
                        ? {
                            height: `${containerHeight}px`,
                            overflow: "hidden",
                          }
                        : undefined
                    }
                  >
                    <FeedsList
                      events={userEvents}
                      fetchPrivateEvents={fetchPrivateEvents}
                      setEvent={setEvent}
                      setEventId={setEventId}
                      setEventTitleBox={setEventTitleBox}
                      setIsLoading={setIsLoading}
                    />
                  </div>
                )}

                {innerTab === INNER_TAB_NAMES.feed && (
                  <NewsFeed
                    openNewsFeed={openNewsFeed}
                    handleClose={handleCloseInternal}
                    shouldOpen={innerTab === INNER_TAB_NAMES.feed}
                  />
                )}

                {innerTab === INNER_TAB_NAMES.past &&
                  (!pastEvents?.length ? (
                    <p className="text-center my-3">No Events available</p>
                  ) : (
                    <div
                      style={
                        containerHeight
                          ? {
                              height: `${containerHeight}px`,
                              overflow: "hidden",
                            }
                          : undefined
                      }
                    >
                      <FeedsList
                        events={pastEvents}
                        setEvent={setEvent}
                        setEventId={setEventId}
                        setEventTitleBox={setEventTitleBox}
                        setIsLoading={setIsLoading}
                      />
                    </div>
                  ))}
              </div>
            )}
            {showMore && (
              <div
                className="show-more-container"
                style={isPreimum ? { bottom: "-20px" } : {}}
              >
                <div className="d-flex flex-column align-items-center button-container">
                  <div className="mb-5">
                    <button className="primary-button" onClick={handleShowMore}>
                      Load More
                    </button>
                  </div>
                </div>
              </div>
            )}
            {!isPreimum && <GoPremimumBanner />}
          </div>
        </div>
      </div>
      <RecentPosts />
      {eventId && (
        <UpdateEvent
          videos={videos}
          event={event}
          eventTitleBox={eventTitleBox}
          isLoading={isLoading}
          setEventId={setEventId}
          eventId={eventId}
        />
      )}
      {!welcomeModalshown && showWelcomeModal && (
        <WelcomeModal toggleWelcomeModal={toggleWelcomeModal} />
      )}
      {show && (
        <ProfileView
          isPreimum={isPreimum}
          show={show}
          setShow={setShow}
          planShow={planShow}
        />
      )}
    </>
  );
};

export default DiscoverFeed;
