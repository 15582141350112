import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useMemo } from "react";
import ProtectedRoute from "./ProtectedRoute"; // Import the ProtectedRoute component
import ProtectedRouteUser from "./ProtectedRouteUser";
import Footer from "./common/Footer";
import Header from "./common/Header";
import DiscoverFeed from "./pages/DiscoverFeed";
import Profile from "./pages/Profile";
import ProfileView from "./pages/ProfileView";
import Signin from "./auth/SignIn";
import Signup from "./auth/Signup";
import VerifyOtp from "./auth/VerifyOtp";
import RequestOtp from "./auth/RequestOtp";
import { Toaster } from "react-hot-toast";
import UnVerifiedModalBox from "./common/UnVerifiedModalBox";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useSelector } from "react-redux";
import configStore from "./store/redux-store";
import ToS from "./pages/ToS";
import Privacy from "./pages/Privacy.jsx";
import { isPremiumUser } from "./helper/isPremium";
import UpdateEvent from "./pages/UpdateEvent.jsx";

const { store, persistor } = configStore();
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Main />
        </Router>
      </PersistGate>
    </Provider>
  );
}

function Main() {
  const pathName = useLocation().pathname;
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated } = auth;
  const user = JSON.parse(localStorage.getItem("user" || "{}"));
  const excludedRoutes = [
    "/reset-password",
    "/signin",
    "/signup",
    "/verify-otp",
  ];
  const isUnverified = useMemo(
    () => user && !user.is_verified && !excludedRoutes.includes(pathName),
    [user?.is_verified, pathName]
  );
  const isPreimum = useMemo(() => {
    return isPremiumUser(user);
  }, [user]);

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="main-site-wrapper">
        <Header isPreimum={isPreimum} />
        {isUnverified && <UnVerifiedModalBox />}

        <Routes>
          <Route path="/reset-password" element={<RequestOtp />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />

          {/* Protected Profile route */}
          {/* <Route
            path="/profile"
            element={
              <ProtectedRoute isVerified={user?.is_verified} user={user}>
                <ProfileView isPreimum={isPreimum} />
              </ProtectedRoute>
            }
          /> */}

          {/* Protected Username route */}
          <Route
            path="/:username"
            element={
              <ProtectedRouteUser isVerified={user?.is_verified} user={user}>
                <Profile isPreimum={isPreimum} />
              </ProtectedRouteUser>
            }
          />

          <Route
            path="/"
            element={
              <DiscoverFeed
                isAuthenticated={isAuthenticated}
                isPreimum={isPreimum}
              />
            }
          />
          <Route path="/event/:eventId" element={<UpdateEvent />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tos" element={<ToS />} />
        </Routes>

        <Footer loggedIn={isAuthenticated} isPreimum={isPreimum} />
      </div>
    </div>
  );
}

export default App;
