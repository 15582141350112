"use client";

import React from "react";
import { useEffect, useState } from "react";
import timeAgo from "../helper/timeAgo";

const RecentPosts = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [newsItems, setNewsItems] = useState([]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? newsItems.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === newsItems.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 150) {
      handleNext();
    }
    if (touchStart - touchEnd < -150) {
      handlePrevious();
    }
  };

  const handleCardClick = (pageUrl) => {
    window.open(pageUrl, "_blank", "noopener,noreferrer");
  };

  const fetchPosts = async () => {
    try {
      const response = await fetch(
        "https://blog.lookingforwardto.com/ghost/api/content/posts/?key=73551c6b25766d5cafab6f66fd&limit=7&include=tags"
      );
      const responseBody = await response.json();
      const posts = responseBody.posts;
      setNewsItems(
        posts.map((post) => ({
          id: post.uuid,
          title: post.title,
          category: post.primary_tag ? post.primary_tag.name : "",
          timeAgo: timeAgo(post.published_at),
          imageUrl: post.feature_image,
          pageUrl: post.url,
        }))
      );
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div className="container">
      <div className="news-container">
        <div className="news-header">
          <div className="nav-buttons">
            <h2 className="news-title">Latest news</h2>
            <button
              onClick={handlePrevious}
              className="nav-button-right"
              aria-label="Previous slide"
            >
              {"<"}
            </button>
            <button
              onClick={handleNext}
              className="nav-button-right"
              aria-label="Next slide"
            >
              {">"}
            </button>
          </div>
          <a
            href="https://blog.lookingforwardto.com/"
            className="go-to-blog-button"
          >
            Go to blog {">"}
          </a>
        </div>
      </div>

      <div className="slider-container">
        <div
          className="slider-track"
          style={{ transform: `translateX(-${currentIndex * 300}px)` }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {newsItems.map((item) => (
            <div
              key={item.id}
              className="news-card"
              onClick={() => handleCardClick(item.pageUrl)}
            >
              <div className="card-content">
                <img
                  src={item.imageUrl || "/placeholder.svg"}
                  alt={item.title}
                  className="news-image"
                />
                <div className="card-overlay"></div>
              </div>
              <div className="card-info">
                <h3 className="card-title">{item.title}</h3>
              </div>
              <div className="card-meta">
                {item.category && (
                  <>
                    <span>{item.category}</span>
                    <span> • </span>
                  </>
                )}
                <span>{item.timeAgo}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentPosts;
