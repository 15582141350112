import React from "react";

const CardSkeleton = ({ isTall = false }) => {
  return (
    <div
      className={`card-wrapper animate-pulse ${isTall ? "tall-card" : ""}`}
      style={{
        width: isTall ? "100%" : "245px",
        height: isTall ? "500px" : "auto",
      }}
    >
 
      <div
        style={{ position: "absolute", zIndex: 4, right: 8, top: 18 }}
        className="bg-gray-300 w-6 h-6 rounded-full"
      ></div>

      <div
        className={`mesonate-image-container ${isTall ? "tall-image" : ""} bg-gray-300`}
        style={{ height: isTall ? "500px" : "250px" }}
      ></div>

      {!isTall && <div className="mt-3 h-5 w-3/4 bg-gray-300 rounded-md"></div>}
      {!isTall && <div className="mt-2 h-4 w-2/3 bg-gray-300 rounded-md"></div>}
      {!isTall && <div className="mt-2 h-3 w-1/2 bg-gray-300 rounded-md"></div>}
    </div>
  );
};

const CardSkeletonGrid = () => {
  return (
    <div className="grid-container" style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
      {[...Array(8)].map((_, index) => (
        <CardSkeleton key={index} />
      ))}

      
    </div>
  );
};

export default CardSkeletonGrid;
