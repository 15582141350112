import React, { forwardRef, memo, useState } from 'react'
import InputMask from 'react-input-mask'
import ErrorMessage from './ErrorMessage'


const InputField = (
  {
    name,
    label,
    placeholder,
    type = 'text',
    onChange,
    value,
    defaultValue,
    disabled,
    readonly,
    maxLength,
    autoFocus,
    hidden,
    id,
    onMouseOut,
    className,
    onBlur,
    onFocus,
    errors,
    mask = '',
    onKeyPress,
    placeholderFontSize = '',
    onWheel,
    onClickIcon,
    rightIcon,
    icon,
    PasswordIcon,
    onKeyDown
  },
  ref
) => {
  const [showPassword, setShowPassword] = useState(false);

  // const _placeholderFontSize = `placeholder:!text-${isEmpty(placeholderFontSize) ? 'base' : placeholderFontSize}`

  const getClasses = () => {
    // const _fontSize = fontSize ? `text-${fontSize}` : 'xs:text-base sm:text-xl'

    // let classes = `border border-grey-light ${bgColor} rounded-xl placeholder:text-grey-darkest1x font-primary outline-0
    //   ${_fontSize} mt-1.5 block w-full px-3 py-2 font-bold placeholder:font-medium ${_placeholderFontSize}
    //   ${icon ? 'pl-12 pr-3 placeholder:!font-medium placeholder:!text-base hover:border hover:border-primary-brandDark !mt-0 text-black' : disabled ? 'cursor-not-allowed px-3 text-primary-disabled' : readonly ? 'cursor-not-allowed' : 'hover:border hover:border-primary-brandDark focus:ring focus:ring-primary-brandLight focus:border focus:border-primary-brandDark shadow-primary-brandDark px-3 text-black'} ${className}`
    
    // if (errors) {
    //   classes = `${classes} border border-primary-error hover:border hover:border-primary-error focus:border-primary-error focus:ring-0 focus:ring-transparent`
    // }
    
    // return classes
    return ''
  }

  return (
    <div className={className}>
      <div className={`${hidden ? 'hidden' : ''}`}>
        {/* {infoText && <InfoText index={index} infoText={infoText} />} */}
      
        <div className={`${icon || rightIcon ? 'flex relative' : ''}`} style={{position:'relative'}}>
          {icon && <img className="absolute top-4 left-5" src={icon} alt="icon" />}
          <InputMask
            onWheel={onWheel}
            aria-invalid={errors}
            autoFocus={autoFocus}
            className={getClasses()}
            data-testid={name}
            defaultValue={defaultValue}
            disabled={disabled}
            hidden={hidden}
            id={id}
            mask={mask}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            onMouseOut={onMouseOut}
            placeholder={placeholder || ''}
            readOnly={readonly}
            inputRef={ref}
            title={label}
            type={type !== 'password' ? type : showPassword ? 'text' : 'password'}
            value={value}
            onKeyDown={onKeyDown}
          />
           {PasswordIcon && (
            <span
              onClick={() => setShowPassword(!showPassword)}
              className='input-password'
            >
              {showPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"

                  className="eye-icon"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path
                    fillRule="evenodd"
                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="eye-icon"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                    clipRule="evenodd"
                  />
                  <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                </svg>
              )}
              {/* <span className="sr-only">
                {showPassword ? "Hide password" : "Show password"}
              </span> */}
            </span>
          )}
          {rightIcon && (
            <span onClick={onClickIcon}>
              <img className="absolute top-6 right-10 cursor-pointer" src={rightIcon} alt="icon" />
            </span>
          )}
        </div>
      </div>
      {errors && <ErrorMessage id={name}>{errors.message}</ErrorMessage>}
    </div>
  )
}

export default memo(forwardRef(InputField))
