import { Controller, useForm } from "react-hook-form";
import InputField from "../common/InputField";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { verifyOtpSchema } from "../validation/yupValidation";
import { API_ROUTES } from "../config/apiRoutes";
import axiosInstance from "../axiosInstance";
import { useState } from "react";
import toast from "react-hot-toast";
import LoadingComponent from "../common/LoadingComponent";

function VerifyOtp() {
	let user = JSON.parse(localStorage.getItem("user" || "{}"));
	const otpData = JSON.parse(localStorage.getItem("otp" || "{}"));

	const [optId, setOtpId] = useState();
	const [isSending, setIsSending] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(verifyOtpSchema),
		mode: "onSubmit",
	});

	const onSubmit = async (data) => {
		setIsLoading(true);
		const response = await axiosInstance.post(API_ROUTES.VERIFY_OTP, {
			otp_id: optId ? optId : otpData._id,
			code: data.code,
		});
		if (response?.data) {
			toast.success("Email Verified");
			localStorage.removeItem("otp");
			localStorage.removeItem("email");
			if (user?.email) {
				user = { ...user, is_verified: true };
				localStorage.setItem("user", JSON.stringify(user));
				navigate("/");
			} else {
				navigate("/signin");
			}
		}
		setIsLoading(false);
	};

	const sendAgain = async () => {
		setIsSending(true);
		let email = localStorage.getItem("email");

		const response = await axiosInstance.post(API_ROUTES.REQUEST_OTP, {
			email: user?.email ?? email,
		});
		if (response?.data) {
			setOtpId(response?.data?.otp?._id);
			toast.success("OTP sent to your email");
		}
		setIsSending(false);
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="auth-scree-wrapper">
				<div className="auth-scree-inner">
					<div className="auth-main-box">
						<h2>Complete Signup</h2>
						<p
							className="simple-link-text"
							style={{ textAlign: "left", paddingBottom: "24px" }}
						>
							A One-Time Password has been sent to your{" "}
							<span className="text-theme-color">{otpData?.email}</span>
							{/* <span className="text-theme-color">test@mailinator.cpom</span> */}
						</p>
						<div className="site-input" style={{ marginBottom: "10px" }}>
							<Controller
								name="code"
								control={control}
								render={({ field }) => {
									return (
										<InputField
											placeholder={"Enter OTP Code"}
											type="text"
											{...field}
											ref={field.ref}
											errors={errors.code}
										/>
									);
								}}
							/>
						</div>
						<div style={{ marginBottom: "26px" }}>
							<p
								className="anchor-link-bottom"
								style={{
									textAlign: "right",
									paddingTop: "0px",
								}}
							>
								Didn't receive it? {/* eslint-disable-next-line */}
								<a
									className="text-underline"
									style={{ cursor: "pointer" }}
									onClick={() => sendAgain()}
								>
									send again
									{isSending && <LoadingComponent />}
								</a>
							</p>
						</div>

						<div className="site-input">
							<button className="site-btn">
								{isLoading && <LoadingComponent />}
								Confirm
							</button>
						</div>
					</div>
					<div className="anchor-link-bottom" style={{ cursor: "pointer" }}>
						{/* eslint-disable-next-line */}
						<a onClick={() => navigate("/signin")}>Back to login</a>
					</div>
				</div>
			</div>
		</form>
	);
}

export default VerifyOtp;
