import React from "react";
import { Modal } from "react-bootstrap";

const ModalCustom = ({
  show,
  handleClose,
  title,
  children, // Add children prop
  modalUpgrade,
  modalBody,
  modalTitle,
}) => {
  return (
    <Modal className={modalUpgrade + " " + "custom-event-modal"} show={show} onHide={handleClose} centered >
      <Modal.Body className={modalBody} style={{backgroundColor:"rgb(34, 34, 34)"}}>
       
        <h3 className={modalTitle}>{title}</h3>
        {children} {/* Render children here */}
      </Modal.Body>
    </Modal>
  );
};

export default ModalCustom;