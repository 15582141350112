import React from "react";

const FireIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
    >
      <path
        d="M10.8559 6.28214C10.4204 5.12596 9.78542 4.05517 8.97983 3.11847C7.58571 1.50465 5.75254 0.462402 4.30956 0.462402C4.22376 0.462466 4.13911 0.48216 4.06209 0.519977C3.98507 0.557793 3.91773 0.61273 3.86521 0.680581C3.8127 0.748433 3.7764 0.8274 3.7591 0.911439C3.7418 0.995479 3.74396 1.08236 3.76541 1.16544C4.25297 3.02848 3.24235 4.57763 2.17268 6.21746C1.28651 7.57572 0.372559 8.97863 0.372559 10.5861C0.372559 13.6872 2.89576 16.2104 5.99685 16.2104C9.09794 16.2104 11.6211 13.6872 11.6211 10.5861C11.6211 9.06405 11.3638 7.61615 10.8559 6.28214ZM7.13331 14.0553C6.77019 14.4419 6.31497 14.5231 5.99685 14.5231C5.67872 14.5231 5.22351 14.4419 4.86039 14.0553C4.49727 13.6686 4.30956 13.0552 4.30956 12.2734C4.30956 11.3897 4.6196 10.7169 4.91909 10.0666C5.09309 9.68905 5.27061 9.30484 5.37607 8.89357C5.38726 8.84883 5.40929 8.80753 5.44021 8.77331C5.47113 8.73909 5.50998 8.71301 5.55336 8.69734C5.59673 8.68168 5.6433 8.67693 5.68894 8.6835C5.73459 8.69007 5.77792 8.70777 5.81511 8.73503C6.13662 8.98378 6.42249 9.27544 6.66473 9.60187C7.30344 10.4455 7.68413 11.4445 7.68413 12.2734C7.68413 13.0552 7.49361 13.6714 7.13331 14.0553Z"
        fill="white"
      />
    </svg>
  );
};

export default FireIcon;
