import React from "react";
import { Modal } from "react-bootstrap";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";

const ModalBox = ({
  Header,
  Body,
  eventId,
  isLoading,
  handleClose, // Added the handleClose prop
  className = "news-feed-modal",
  contentClassName,
  dialogClassName = "d-flex justify-content-center",
}) => {
  const navigate = useNavigate();

  // Use the provided handleClose if available, otherwise navigate to home
  const onClose = () => {
    if (typeof handleClose === "function") {
      handleClose();
    } else {
      navigate("/", { replace: true });
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Modal
      show={!!eventId}
      onHide={onClose} // Use our new onClose function
      size="lg"
      className={className}
      contentClassName={contentClassName}
      dialogClassName={dialogClassName}
    >
      <Modal.Header>
        <Modal.Title className="w-100">{Header}</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={onClose} // Use our new onClose function
        ></button>
      </Modal.Header>
      <Modal.Body>{Body}</Modal.Body>
    </Modal>
  );
};

export default ModalBox;
