import React from "react";

const TabsComponent = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div>
      <div className="activeTabWrapper">
        {tabs.map((tab, index) => (
          <div className="tabWrapper" key={index}>
            <div
              key={index}
              onClick={() => onTabChange(tab.label)}
              className={`${
                activeTab === tab.label ? "activeFeedTab" : ""
              } feedTab`}
            >
              {tab.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabsComponent;
