import React from "react";
import FireIcon from "./svgs/FireIcon";

const Tags = ({ title, followed }) => {
  return (
    <div className="mesonate-top-text">
      <span className="mesonate-label" style={{ cursor: "pointer" }}>
        <FireIcon />
        {followed}
      </span>
      {title && <span className="mesonate-label">{title}</span>}
    </div>
  );
};

export default Tags;
