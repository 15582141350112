import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "../slices/index";
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
	const store = configureStore({ reducer: persistedReducer });
	const persistor = persistStore(store);
	return { store, persistor };
};
