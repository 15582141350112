import { createSlice } from "@reduxjs/toolkit";

import { calculateNextEventDate } from "../helper";

const getUpComingEventofUser = (user) => {
	const acceptedEvents = user?.accepted_events || [];
	const noDateEvents = acceptedEvents?.filter(
		(event) =>
			calculateNextEventDate(event.start_date, event.repeat_days) === null
	);
	const futureEvents = acceptedEvents?.filter((event) => {
		const startDate = calculateNextEventDate(
			event.start_date,
			event.repeat_days
		);
		return startDate > new Date();
	});
	return [...futureEvents, ...noDateEvents];
};

const initialState = {
	isAuthenticated: false,
	welcomeModalshown: false,
	user: null,
	token: "",
	upComingEvents: [],
	updateSessionId: "",
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		login(state, action) {
			const { user, token } = action.payload;
			state.upComingEvents = getUpComingEventofUser(user);
			if (user.login_type === "guest") {
				state.isAuthenticated = false;
			} else {
				state.isAuthenticated = true;
			}
			state.user = user;
			state.token = token;
		},
		logout(state) {
			state.isAuthenticated = false;
			state.user = null;
			state.token = "";
		},
		setUserData(state, action) {
			const user = action.payload;
			if (user.accepted_events) {
				state.upComingEvents = getUpComingEventofUser(user);
			}
			state.user = user;
		},
		setUpdateSessionId(state, action) {
			state.updateSessionId = action.payload;
		},
		setWelcomeModalShown(state, action) {
			state.welcomeModalshown = action.payload;
		},
	},
});

export const {
	login,
	logout,
	setUserData,
	setUpdateSessionId,
	setWelcomeModalShown,
} = authSlice.actions;
export default authSlice.reducer;
