import { useState, useRef, useEffect } from "react";
import React, { forwardRef } from "react";
import axios from "axios";
import { API_ROUTES } from "../config/apiRoutes";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ImageCropperPopup from "./ImageCropperPopup";
import { IoChevronBack } from "react-icons/io5";
import axiosInstance from "../axiosInstance";

const CreateEvent = ({ onClose, onEventCreated, fetchPrivateEvents }) => {
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [fullImageFile, setFullImageFile] = useState(null);
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [noDateTitle, setNoDateTitle] = useState("Coming Soon");
  const [postShape, setPostShape] = useState("2");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const authToken = useSelector((state) => state.auth.token);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const fileInputRef = useRef(null);
  const [fullImageUrl, setFullImageUrl] = useState("");
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [isNoDate, setIsNoDate] = useState(false);
  const [originalFullImageFile, setOriginalFullImageFile] = useState(null);
  const [cropperKey, setCropperKey] = useState(0);
  const [isImageSelectorOpen, setIsImageSelectorOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [images, setImages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const PEXELS_API_KEY =
    "JHzjrhBbOUMKzW1odHBXlKRpgUwGOnrRTYKsF7iVbU1q2ntLxO8LdpgB";

  const aspectRatios = {
    2: 1, // Square-like
    3: 9 / 16, // Vertical Rectangle (9:16 aspect ratio)
  };

  // Fetch images from Pexels when query or page changes
  useEffect(() => {
    if (isImageSelectorOpen) {
      fetchImages();
    }
  }, [pageNumber, query, isImageSelectorOpen]);

  const fetchImages = async () => {
    setLoading(true);
    try {
      const url =
        query.trim() === ""
          ? `https://api.pexels.com/v1/curated?page=${pageNumber}&per_page=10`
          : `https://api.pexels.com/v1/search?query=${encodeURIComponent(
              query
            )}&page=${pageNumber}&per_page=10`;

      const response = await axios.get(url, {
        headers: {
          Authorization: PEXELS_API_KEY,
        },
      });

      setImages(response.data.photos);
    } catch (error) {
      console.error("Error fetching images from Pexels:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPageNumber(1); // Reset to first page when searching
    fetchImages();
  };

  const handleImageSelect = (photo) => {
    downloadImage(photo.src.original, photo.id);
  };

  const downloadImage = async (imageUrl, photoId) => {
    try {
      setLoading(true);
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const file = new File([blob], `pexels-image-${photoId}.jpg`, {
        type: "image/jpeg",
      });

      const localUrl = URL.createObjectURL(file);

      setFullImageFile(file);
      setOriginalFullImageFile(file);
      setFullImageUrl(localUrl);
      setIsImageSelectorOpen(false);
      setIsCropperOpen(true);
    } catch (error) {
      console.error("Error downloading image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFullImageFile(file);
      setOriginalFullImageFile(file);
      setFullImageUrl(imageUrl);
      setIsImageSelectorOpen(false);
      setIsCropperOpen(true);
    }
  };

  useEffect(() => {
    if (fullImageUrl) {
      const img = new Image();
      img.src = fullImageUrl;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const aspectRatio = aspectRatios[postShape];

        let cropWidth, cropHeight;

        if (postShape === "2") {
          // Square
          cropWidth = Math.min(width, height);
          cropHeight = cropWidth;
        } else {
          // Vertical Rectangle
          cropWidth = width;
          cropHeight = width / aspectRatio;

          if (cropHeight > height) {
            cropHeight = height;
            cropWidth = height * aspectRatio;
          }
        }

        setCrop({
          unit: "%",
          x: ((width - cropWidth) / 2 / width) * 100,
          y: ((height - cropHeight) / 2 / height) * 100,
          width: (cropWidth / width) * 100,
          height: (cropHeight / height) * 100,
          aspect: aspectRatio,
        });
      };
    }
  }, [postShape, fullImageUrl]);

  const handleFullImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFullImageFile(file);
      setOriginalFullImageFile(file);
      const imageUrl = URL.createObjectURL(file);
      setFullImageUrl(imageUrl);
      setIsCropperOpen(true);
      setCrop(null); //reset crop
      setCropperKey((prevKey) => prevKey + 1); // Increment cropperKey
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const aspectRatio = aspectRatios[postShape];
        setIsCropperOpen(true);
        let cropWidth, cropHeight;

        if (postShape === "2") {
          // Square
          cropWidth = Math.min(width, height);
          cropHeight = cropWidth;
        } else {
          // Vertical Rectangle
          cropWidth = width;
          cropHeight = width / aspectRatio;

          if (cropHeight > height) {
            cropHeight = height;
            cropWidth = height * aspectRatio;
          }
        }

        setCrop({
          unit: "%",
          x: ((width - cropWidth) / 2 / width) * 100,
          y: ((height - cropHeight) / 2 / height) * 100,
          width: (cropWidth / width) * 100,
          height: (cropHeight / height) * 100,
          aspect: aspectRatio,
        });
      };
    }
  };

  useEffect(() => {
    setCrop({ aspect: aspectRatios[postShape] });
  }, [postShape]);

  const handleCroppedImageComplete = (croppedImageUrl) => {
    setCroppedImageUrl(croppedImageUrl);
  };

  const handleCropperClose = () => {
    setIsCropperOpen(false);
    setFullImageUrl("");
  };

  const handleCropChange = (newCrop) => {
    // Allow only x and y changes (panning), prevent width/height changes
    if (crop && newCrop) {
      setCrop({
        ...crop,
        x: newCrop.x !== undefined ? newCrop.x : crop.x,
        y: newCrop.y !== undefined ? newCrop.y : crop.y,
      });
    }
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const handleCroppedImageUpload = () => {
    if (!completedCrop || !imgRef.current || !fullImageFile) return;

    const image = imgRef.current;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    canvas.toBlob((blob) => {
      if (blob) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCroppedImageUrl(reader.result);
        };
        reader.readAsDataURL(blob);
      }
    }, "image/jpeg");
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {

      const response = await axiosInstance.post(
        API_ROUTES.UPLOAD_SINGLE,
        formData
      );

      console.log("Uploaded Image URL:", response.data.url); // Log image URL
      return response.data.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handlePostShapeSelection = (shape) => {
    setPostShape(shape);
    setCrop(null); //reset crop
    if (originalFullImageFile) {
      const imageUrl = URL.createObjectURL(originalFullImageFile);
      setFullImageUrl(imageUrl);
      setIsCropperOpen(true);
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const aspectRatio = aspectRatios[shape];

        let cropWidth, cropHeight;

        if (shape === "2") {
          // Square
          cropWidth = Math.min(width, height);
          cropHeight = cropWidth;
        } else {
          // Vertical Rectangle
          cropWidth = width;
          cropHeight = width / aspectRatio;

          if (cropHeight > height) {
            cropHeight = height;
            cropWidth = height * aspectRatio;
          }
        }

        setCrop({
          unit: "%",
          x: ((width - cropWidth) / 2 / width) * 100,
          y: ((height - cropHeight) / 2 / height) * 100,
          width: (cropWidth / width) * 100,
          height: (cropHeight / height) * 100,
          aspect: aspectRatio,
        });
      };
    }
  };

  useEffect(() => {
    if (fullImageUrl) {
      const img = new Image();
      img.src = fullImageUrl;
      img.onload = () => {
        // ... (your existing image load logic)
      };
    }
  }, [postShape, fullImageUrl]);

  const [fieldErrors, setFieldErrors] = useState({
    title: "",
    startDate: "",
    fullImageFile: "",
    croppedImageUrl: "",
    noDateTitle: "",
    postShape: "",
  });

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      value={value || ""}
      onClick={onClick}
      placeholder="mm / dd / yy"
      readOnly
      style={{
        padding: "9px",
        backgroundColor: "rgb(34, 34, 34)",
        borderRadius: "9px",
        color: "white",
        outline: "none",
        transition: "border-color 0.3s ease, box-shadow 0.3s ease",
        border: `2px solid ${false ? "red" : "grey"}`,
        marginBottom: "14px",
      }}
      ref={ref}
    />
  ));

  const handleStartDateChange = (date) => {
    if (isNoDate) {
      setStartDate(null);
    } else if (date) {
      const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0, // Hours
        0, // Minutes
        0 // Seconds
      );
      setStartDate(newDate);
    } else {
      setStartDate(null);
    }
    setFieldErrors({ ...fieldErrors, startDate: "" });
  };

  const handleButtonClick = () => {
    imgRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
    setIsSubmitting(true);

    let errors = {};

    if (!title) {
      errors.title = "Title is required.";
    }
    if (!isNoDate && !startDate) {
      errors.startDate = "Start date is required.";
    }
    if (!fullImageFile) {
      errors.fullImageFile = "Full image is required.";
    }
    if (!croppedImageUrl) {
      errors.croppedImageUrl = "Cropped image is required.";
    }
    if (isNoDate && !noDateTitle) {
      errors.noDateTitle = "No date title is required";
    }
    if (!postShape) {
      errors.postShape = "Post shape is required";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      setIsSubmitting(false);
      return;
    }

    setFieldErrors({});

    let formattedStartDate = null;
    if (!isNoDate && startDate) {
      // Format the date as YYYY-MM-DD for backend consistency
      formattedStartDate = startDate.toISOString().split("T")[0];
    }

    try {
      // const fullImageUrlUploaded = await uploadImage(fullImageFile);
      
      const croppedBlob = await fetch(croppedImageUrl).then((r) => r.blob());
      const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
        type: "image/jpeg",
      });
      const imageUrlUploaded = await uploadImage(croppedFile);

      const params = {
        title,
        start_date: formattedStartDate,
        full_image: imageUrlUploaded,
        cropped_image: imageUrlUploaded,
        no_date_title: isNoDate ? noDateTitle : "",
        post_shape: postShape,
      };

      const response = await axiosInstance.post(
        API_ROUTES.CREATE_EVENT,
        params
      );

      if (response.status === 200) {
        setSuccessMessage("Event created successfully!");
        fetchPrivateEvents();
        console.log("Event Details:", response.data);
        onEventCreated(response.data.event);
        console.log("Event created successfully!");
      } else {
        console.log("Event data not received from server.");
        setError(response.data?.message || "Failed to create event.");
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data?.message || "An error occurred.");
        console.error("Create event error:", err.response.data);
      } else if (err.request) {
        setError("Network error. Please try again.");
        console.error("Create event error:", err.request);
      } else {
        setError("An unexpected error occurred.");
        console.error("Create event error:", err.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemoveImage = () => {
    // Clear image-related states
    setFullImageFile(null);
    setOriginalFullImageFile(null);
    setFullImageUrl("");
    setCroppedImageUrl("");

    // Also clear related errors
    setFieldErrors({
      ...fieldErrors,
      fullImageFile: "",
      croppedImageUrl: "",
    });
  };

  // Render the appropriate content based on state
  const renderContent = () => {
    if (isImageSelectorOpen) {
      return (
        <div className="image-selector-container">
          <div className="event-back-image">
            <button onClick={() => setIsImageSelectorOpen(false)}>
              <IoChevronBack />
              <span>Image</span>
            </button>
          </div>

        <div className="search-event-image-box">
          <form onSubmit={handleSearch} className="event-image-search">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search"
            />
          </form>

          {loading ? (
            <div>Loading...</div>
          ) : (
            <div
              className="event-photos-wrapper"
            >
              {images.map((photo) => (
                <div
                  key={photo.id}
                  onClick={() => handleImageSelect(photo)}
                  className="event-photo-item"
                >
                  <img
                    src={photo.src.medium}
                    alt={photo.photographer}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

          <div onClick={() => fileInputRef.current.click()} className="upload-event-image">
            <span>Upload .jpg or .png (max 10MB)</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M17 11.6665V15.2221C17 15.6936 16.8127 16.1457 16.4793 16.4791C16.1459 16.8125 15.6937 16.9998 15.2222 16.9998H2.77778C2.30628 16.9998 1.8541 16.8125 1.5207 16.4791C1.1873 16.1457 1 15.6936 1 15.2221V11.6665" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.4446 5.44444L9.00011 1L4.55566 5.44444" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 1V11.6667" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/jpeg, image/png"
              onChange={handleFileUpload}
            />
          </div>

          {/* <div>
            <button
              onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
              disabled={pageNumber === 1}
              style={{
                cursor: pageNumber === 1 ? "not-allowed" : "pointer",
              }}
            >
              Previous
            </button>
            <button onClick={() => setPageNumber((prev) => prev + 1)}>
              Next
            </button>
          </div> */}
        </div>
      );
    }

    // Default view - create event form
    return (
      <form onSubmit={handleSubmit} className="create-event-form">
        {error && <p style={{ color: "red" }}>{error}</p>}
        {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
        <div className="box-form-input mb-20">
          <div>
            <input
              type="text"
              placeholder="Name"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setFieldErrors({ ...fieldErrors, title: "" }); // Clear error on change
              }}
              style={{
                padding: "9px",
                backgroundColor: "rgb(34, 34, 34)",
                borderRadius: "9px",
                color: "white",
                outline: "none",
                transition: "border-color 0.3s ease, box-shadow 0.3s ease",
                border: `2px solid ${
                  fieldErrors.title ? "rgb(252, 118, 118)" : "grey"
                }`,
                marginBottom: "14px",
              }}
            />
          </div>
          <div>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="MM/dd/yy"
              disabled={isNoDate}
              placeholderText=" mm / dd / yy "
              customInput={<CustomInput />}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "9px",
              backgroundColor: "rgb(34, 34, 34)",
              borderRadius: "9px",
              color: "white",
              outline: "none",
              transition: "border-color 0.3s ease, box-shadow 0.3s ease",
              border: "0.725px solid rgba(255, 255, 255, 0.20)",
              border: `2px solid ${
                fieldErrors.noDateTitle ? "red" : "rgba(255, 255, 255, 0.20)"
              }`,
            }}
          >
            <label>No Date</label>
            <label
              style={{
                position: "relative",
                display: "inline-block",
                width: "50px",
                height: "24px",
                marginLeft: "auto",
              }}
            >
              <input
                type="checkbox"
                checked={isNoDate}
                onChange={() => setIsNoDate(!isNoDate)}
                style={{
                  opacity: 0,
                  width: 0,
                  height: 0,
                  position: "absolute",
                }}
              />
              <span
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: isNoDate ? "#FF8B3D" : "#ccc",
                  transition: "0.4s",
                  borderRadius: "24px",
                }}
              ></span>
              <span
                style={{
                  position: "absolute",
                  content: '""',
                  height: "18px",
                  width: "18px",
                  left: "4px",
                  bottom: "3px",
                  backgroundColor: "white",
                  transition: "0.4s",
                  borderRadius: "50%",
                  transform: isNoDate ? "translateX(26px)" : "translateX(0px)",
                }}
              ></span>
            </label>
          </div>
          {/* Show No Date Title input only when toggle is ON */}
          {isNoDate && (
            <div>
              <input
                type="text"
                style={{
                  padding: "9px",
                  width: "100%",
                  backgroundColor: "rgb(34, 34, 34)",
                  borderRadius: "9px",
                  color: "white",
                  outline: "none",
                  transition: "border-color 0.3s ease, box-shadow 0.3s ease",
                  border: `2px solid ${
                    fieldErrors.noDateTitle ? "red" : "grey"
                  }`,
                  marginBottom: "14px",
                }}
                placeholder="No date name"
                onChange={(e) => setNoDateTitle(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="box-form-input box-form-input-grid">
          {/* Square Shape */}
          <div
            onClick={() => handlePostShapeSelection("2")}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "5px",
              border:
                postShape === "2" ? "2px solid #FF8B3D" : "1px solid white",
              padding: "5px",
              cursor: "pointer",
              backgroundColor:
                postShape === "2" ? "rgba(255, 140, 0, 0.2)" : "transparent",
            }}
            className="grid-box-wrapper"
          >
            <div
              style={{
                width: "90px",
                height: "80px",
                backgroundColor: "grey",
              }}
            ></div>
            <div
              style={{
                width: "93px",
                height: "164px",
                backgroundColor: "grey",
                gridRow: "1 / 3",
                gridColumn: "2 / 3",
              }}
            ></div>
            <div
              style={{
                width: "90px",
                height: "78px",
                backgroundColor: "#FFA467", // Always orange
              }}
            ></div>
          </div>

          {/* Rectangle Shape */}
          <div
            onClick={() => handlePostShapeSelection("3")}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "5px",
              border:
                postShape === "3" ? "2px solid #FF8B3D" : "1px solid white",
              padding: "5px",
              cursor: "pointer",
              backgroundColor:
                postShape === "3" ? "rgba(255, 140, 0, 0.2)" : "transparent",
            }}
            className="grid-box-wrapper"
          >
            <div
              style={{
                width: "90px",
                height: "80px",
                backgroundColor: "grey",
              }}
            ></div>
            <div
              style={{
                width: "93px",
                height: "164px",
                backgroundColor: "#FFA467", // Always orange
                gridRow: "1 / 3",
                gridColumn: "2 / 3",
              }}
            ></div>
            <div
              style={{
                width: "90px",
                height: "80px",
                backgroundColor: "grey",
              }}
            ></div>
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <div style={{ position: "relative", marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Find or upload image"
                readOnly
                value={fullImageFile ? fullImageFile.name : ""}
                style={{
                  padding: "13px 19px",
                  width: "100%",
                  backgroundColor: "rgb(34, 34, 34)",
                  borderRadius: "8px",
                  color: "white",
                  outline: "none",
                  border: "0.725px solid rgba(255, 255, 255, 0.20)",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                  marginTop: "20px",
                }}
                onClick={() => setIsImageSelectorOpen(true)}
              />
              <button
                type="button"
                onClick={
                  fullImageFile
                    ? handleRemoveImage
                    : () => setIsImageSelectorOpen(true)
                }
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  background: "transparent",
                  border: "none",
                  color: "white",
                  fontSize: fullImageFile ? "18px" : "24px",
                  cursor: "pointer",
                  marginTop: "8px",
                }}
              >
                {fullImageFile ? (
                  // Trash icon
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 9V15M11 9V15M1 5H17M16 5L15.133 17.142C15.0971 17.6466 14.8713 18.1188 14.5011 18.4636C14.1309 18.8083 13.6439 19 13.138 19H4.862C4.35614 19 3.86907 18.8083 3.49889 18.4636C3.1287 18.1188 2.90292 17.6466 2.867 17.142L2 5H16ZM12 5V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1H7C6.73478 1 6.48043 1.10536 6.29289 1.29289C6.10536 1.48043 6 1.73478 6 2V5H12Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  ">"
                )}
              </button>
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="create-event-btn"
          onMouseOver={(e) => (e.target.style.backgroundColor = "#FF9040")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#FFA467")}
        >
          {isSubmitting ? "Creating..." : "Create Event"}
        </button>
      </form>
    );
  };

  return (
    <div className="create-event-container" style={{ color: "white" }}>
      {isCropperOpen && (
        <ImageCropperPopup
          imageUrl={fullImageUrl}
          crop={crop}
          setCrop={setCrop}
          onComplete={handleCroppedImageComplete}
          onClose={handleCropperClose}
          postShape={postShape}
          aspectRatios={aspectRatios}
        />
      )}
      {renderContent()}
    </div>
  );
};

export default CreateEvent;
