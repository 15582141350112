import { useState } from "react";
import { PASSWORD } from "../constant";
import PasswordReset from "./PasswordReset";
import { Controller, useForm } from "react-hook-form";
import { requestOtpSchema } from "../validation/yupValidation";
import { API_ROUTES } from "../config/apiRoutes";
import InputField from "../common/InputField";
import axiosInstance from "../axiosInstance";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import LoadingComponent from "../common/LoadingComponent";

function RequestOtp() {
  const [activeTab, steActiveTab] = useState(PASSWORD.REQUEST);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [otpData, setOptData] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(requestOtpSchema),
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    setEnteredEmail(data.email);
    const response = await axiosInstance.post(API_ROUTES.REQUEST_OTP, {
      email: data.email,
    });
    if (response?.data) {
      toast.success("OTP sent to your email");
      setOptData(response?.data?.otp);
      steActiveTab(PASSWORD.RESET);
    }
    setIsLoading(false);
  };
  return activeTab === PASSWORD.REQUEST ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="auth-scree-wrapper">
        <div className="auth-scree-inner">
          <div className="auth-main-box">
            <h2>Password reset</h2>
            <div className="site-input">
              <Controller
                name="email"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      placeholder={"Enter Email"}
                      type="text"
                      {...field}
                      ref={field.ref}
                      errors={errors.email}
                    />
                  );
                }}
              />{" "}
            </div>
            <div className="site-input">
              <button className="site-btn" type="submit" disabled={isLoading}>
                <div style={{ display: "flex", gap: "4px" }}>
                  {isLoading && <LoadingComponent />}
                  <p>Send reset link</p>
                </div>
              </button>
            </div>
          </div>
          <div className="anchor-link-bottom">
            <a onClick={() => navigate("/signin")}>Back to login</a>
          </div>
        </div>
      </div>
    </form>
  ) : (
    <PasswordReset otpData={otpData} enteredEmail={enteredEmail} />
  );
}

export default RequestOtp;
