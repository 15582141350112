import React, { useEffect, useMemo, useState } from "react";
import { API_ROUTES } from "../config/apiRoutes";
import axiosInstance from "../axiosInstance";
import {
  calculateNextEventDate,
  getUpcomingDate,
  processEventData,
} from "../helper";
import placeholder from "../images/placeholder.png";
import LoadingComponent from "../common/LoadingComponent";
import SettingIcon from "../images/setting-icon.png";
import Event from "../common/Event";
import { useNavigate } from "react-router-dom";
import Setting from "./Setting";
import ModalBox from "../common/ModalBox";
import FeedsList from "./FeedsList";

const Profile = ({ isPreimum = false }) => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [eventId, setEventId] = useState();
  const [event, setEvent] = useState();
  const [events, setEvents] = useState([]);
  const [eventTitleBox, setEventTitleBox] = useState({});
  const [iFetchNewsEvent, setIsFetchNewsEvent] = useState(false);
  const [isOpenSetting, setIsOpenSetting] = useState(false);
  const [pathUrl, setPathUrl] = useState(true);
  const userDataLocal = JSON.parse(localStorage.getItem("user" || "{}"));
  const userData = useMemo(
    () => ({
      name: `${userDataLocal?.full_name ?? user?.full_name}`,
      avatar: userDataLocal?.avatar ?? user?.avatar,
      username: userDataLocal?.username ?? user?.username,
      email: userDataLocal?.email ?? user?.email,
      bio: userDataLocal?.bio ?? user?.bio,
    }),
    // eslint-disable-next-line
    [userDataLocal, user]
  );

  const fetchProfile = async () => {
    // const userName = location.pathname.split("/")[1];
    setIsLoading(true);
    // const response = await axiosInstance.post(API_ROUTES.GET_USER_DATA);

    const currentUrl = window.location.href; // Get the current URL
    const urlParts = currentUrl.split("/"); // Split the URL to extract the username
    const userName = urlParts[urlParts.length - 1]; // Assume username is the last part of the URL

    let apiEndpoint = "";
    if (currentUrl.startsWith("https://lf.to/")) {
      apiEndpoint = `${API_ROUTES.PROFILE_BY_USER_NAME}/public/${userName}`;
      setPathUrl(false);
    } else {
      apiEndpoint = `${API_ROUTES.PROFILE_BY_USER_NAME}/${userName}`;
      setPathUrl(true);
      // await loginGuest();
    }

    const response = await axiosInstance.get(apiEndpoint);
    if (response) {
      let user = response.data.user;
      localStorage.setItem("user", JSON.stringify(user));
      if (user?.accepted_events?.length) {
        if (user?.isCustomOrderApplied) {
          const sortedEvents = [];
          const allEvents = [...user.accepted_events];
          user?.eventOrder?.map?.((or) => {
            const index = allEvents.findIndex((e) => e._id === or.event_id);
            console.log({ index });
            if (index > -1) {
              const len = sortedEvents.push(allEvents[index]) - 1;
              allEvents.splice(index, 1);
              sortedEvents[len] = {
                ...sortedEvents[len],
                upcoming: getUpcomingDate(
                  sortedEvents[len].start_date,
                  sortedEvents[len].repeat_days
                ),
                duration: processEventData(sortedEvents[len]),
              };
            }
          });

          const events = allEvents
            ?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
            .map((sortData) => ({
              ...sortData,
              upcoming: getUpcomingDate(
                sortData.start_date,
                sortData.repeat_days
              ),
              duration: processEventData(sortData),
            }));
          user = { ...user, accepted_events: [...sortedEvents, ...events] };
        } else {
          const events = user?.accepted_events
            ?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
            .map((sortData) => ({
              ...sortData,
              upcoming: getUpcomingDate(
                sortData.start_date,
                sortData.repeat_days
              ),
              duration: processEventData(sortData),
            }));
          user = { ...user, accepted_events: events };
        }

        setUser(user);
      } else {
        setUser(user);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchAndSetEvents = async () => {
    try {
      // Fetch private events from the API
      const privateEventsResponse = await axiosInstance.post(
        API_ROUTES.PRIVATE_EVENTS
      );

      if (privateEventsResponse?.data) {
        // Combine the user's accepted events with the fetched private events
        const acceptedEvents = (user?.accepted_events || []).concat(
          privateEventsResponse?.data
        );

        // Filter events with no specific date
        const noDateEvents = acceptedEvents.filter(
          (event) =>
            calculateNextEventDate(event?.start_date, event?.repeat_days) ===
            null
        );

        // Filter future events based on calculated start dates
        const futureEvents = acceptedEvents.filter((event) => {
          const startDate = calculateNextEventDate(
            event?.start_date,
            event?.repeat_days
          );
          return startDate > new Date();
        });

        // Combine future and no-date events
        let allEvents = [...futureEvents, ...noDateEvents];

        // Sort events by creation date
        allEvents.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        // Process the events (add 'upcoming' and 'duration' properties)
        const processedEvents = allEvents.map((event) => {
          return {
            ...event,
            upcoming: getUpcomingDate(event?.start_date, event?.repeat_days),
            duration: processEventData(event),
          };
        });

        // Set the processed events to the state
        setEvents(processedEvents);
      }
    } catch (error) {
      console.error("Error fetching private events:", error);
    }
  };

  useEffect(() => {
    // Skip the effect if user or accepted_events is undefined
    if (!user || !user.accepted_events) return;

    // Call the async function
    fetchAndSetEvents();

    // Cleanup function
    return () => {
      // Cleanup code here if necessary
    };

    // Use JSON.stringify to ensure deep comparison of the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user?.accepted_events)]);

  const navigate = useNavigate();

  const copyToClipboard = (username) => {
    const urlToCopy = `https://lf.to/${username}`;
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        console.log("copy", urlToCopy);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const removeEvent = (eventId) => {
    if (user?.accepted_events?.length > 0) {
      debugger;
      const clone = [...user.accepted_events];
      const index = clone.findIndex((e) => e._id === eventId);
      if (index > -1) {
        clone.splice(index, 1);
        setUser({ ...user, accepted_events: clone });
      }
    }
  };

  return isLoading ? (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="profile-center">
        <LoadingComponent width="3em" height="3em" />
      </div>
    </div>
  ) : (
    <>
      {user?.email && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "6px",
          }}
        >
          <img
            className="profile-image-mobile"
            src="../images/logo.png"
            alt="logo"
            width={114}
            height={40}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      <div className="profile-wrapper">
        {user?.email && (
          <img
            className="profile-image-desktop"
            src="../images/logo.png"
            alt="logo"
            width={114}
            height={50}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        )}
        <div className="user-profile-wrapper">
          <div className="user-profile-inner" style={{ position: "relative" }}>
            {user?.email && (
              <div className="user-profile-meta">
                <div className="in-left">
                  <img
                    src={userData?.avatar ?? user?.avatar ?? placeholder}
                    alt="img"
                  />
                </div>
                <div className="in-right">
                  <div className="profile-float-top">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="14"
                      viewBox="0 0 13 14"
                      fill="none"
                    >
                      <path
                        d="M11.8 5.8H1M11.8 6.7V5.08C11.8 4.07191 11.8 3.56786 11.6038 3.18282C11.4312 2.84413 11.1559 2.56876 10.8172 2.39619C10.4321 2.2 9.92809 2.2 8.92 2.2H3.88C2.87191 2.2 2.36786 2.2 1.98282 2.39619C1.64413 2.56876 1.36876 2.84413 1.19619 3.18282C1 3.56786 1 4.07191 1 5.08V10.12C1 11.1281 1 11.6321 1.19619 12.0172C1.36876 12.3559 1.64413 12.6312 1.98282 12.8038C2.36786 13 2.87191 13 3.88 13H6.7M8.8 1V3.4M4 1V3.4M9.69856 9.22716C9.2787 8.75968 8.57855 8.63393 8.05249 9.062C7.52643 9.49008 7.45236 10.2058 7.86548 10.7121C8.2786 11.2184 9.69856 12.4 9.69856 12.4C9.69856 12.4 11.1185 11.2184 11.5316 10.7121C11.9448 10.2058 11.8797 9.48557 11.3446 9.062C10.8095 8.63843 10.1184 8.75968 9.69856 9.22716Z"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {/* {moment(user?.createdAt).date()} */}
                    {user?.accepted_events?.flat()?.length}
                  </div>
                  <h3>{userData?.name}</h3>
                  <p>{userData?.bio}</p>
                  <small>lf.to/{userData?.username}</small>
                  <div
                    className="profile-float-bottom"
                    onClick={() => copyToClipboard(`${user?.username}`)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="14"
                      viewBox="0 0 132 130"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_40000165_2896)">
                        <path
                          d="M18.9941 101.465H82.2754C94.873 101.465 101.318 95.0684 101.318 82.6172V18.9453C101.318 6.54297 94.873 0.146484 82.2754 0.146484H18.9941C6.34766 0.146484 0 6.49414 0 18.9453V82.6172C0 95.1172 6.34766 101.465 18.9941 101.465ZM19.1895 92.8223C12.3535 92.8223 8.64258 89.209 8.64258 82.1777V19.4336C8.64258 12.4023 12.3535 8.78906 19.1895 8.78906H82.1289C88.8184 8.78906 92.627 12.4023 92.627 19.4336V82.1777C92.627 89.209 88.8184 92.8223 82.1289 92.8223H19.1895Z"
                          fill="white"
                          fill-opacity="0.85"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M49.5605 129.541H112.842C125.439 129.541 131.836 123.145 131.836 110.693V47.0703C131.836 34.6191 125.439 28.2227 112.842 28.2227H49.5605C36.9141 28.2227 30.5664 34.5703 30.5664 47.0703V110.693C30.5664 123.193 36.9141 129.541 49.5605 129.541ZM49.7559 120.898C42.9199 120.898 39.209 117.285 39.209 110.254V47.5098C39.209 40.4785 42.9199 36.8652 49.7559 36.8652H112.695C119.385 36.8652 123.193 40.4785 123.193 47.5098V110.254C123.193 117.285 119.385 120.898 112.695 120.898H49.7559Z"
                          fill="white"
                          fill-opacity="0.85"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_40000165_2896">
                          <rect width="131.836" height="129.541" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {/* {moment(user?.createdAt).date()} */}
                    copy
                  </div>
                </div>
              </div>
            )}
            <div className="user-profile-list">
              {user?.accepted_events?.length > 0 && (
                <FeedsList
                  events={events}
                  setEvent={setEvent}
                  setEventId={setEventId}
                  setIsLoading={setIsFetchNewsEvent}
                  isProfile={true}
                  removeEvent={removeEvent}
                  drag={isPreimum}
                  setEventTitleBox={setEventTitleBox}
                />
              )}
            </div>
          </div>
        </div>

        <Event
          event={event}
          eventTitleBox={eventTitleBox}
          isLoading={iFetchNewsEvent}
          setEventId={setEventId}
          eventId={eventId}
          setUser={setUser}
        />
        {user?._id && user?._id === userDataLocal?._id && pathUrl && (
          <div
            className="settng-profile-icon-header"
            onClick={() => setIsOpenSetting(true)}
            style={{ cursor: "pointer" }}
          >
            <img width={40} height={40} src={SettingIcon} alt="icon" />
          </div>
        )}
        {/* {true &&  */}
        <ModalBox
          Body={<Setting isLoading={false} />}
          eventId={isOpenSetting}
          handleClose={() => setIsOpenSetting(false)}
          Header={<h2 className="my-3">Settings</h2>}
          contentClassName="user-profile-settings-modal"
          dialogClassName="d-flex justify-content-center"
        />
        {/* } */}
      </div>
    </>
  );
};

export default Profile;
