import { Controller, useForm } from "react-hook-form";
import InputField from "../common/InputField";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "../validation/yupValidation";
import { API_ROUTES } from "../config/apiRoutes";
import axiosInstance from "../axiosInstance";
import { useState } from "react";
import toast from "react-hot-toast";
import LoadingComponent from "../common/LoadingComponent";

function PasswordReset({ otpData, enteredEmail }) {
  const [optId, setOtpId] = useState();
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const response = await axiosInstance.post(API_ROUTES.RESET_PASSWORD, {
      otp_id: optId ? optId : otpData._id,
      password: data.password,
      code: data.code,
    });
    if (response?.data) {
      toast.success("Password updated successfully");
      navigate("/signin");
    }
    setIsLoading(false);
  };

  const sendAgain = async () => {
    setIsSending(true);
    const response = await axiosInstance.post(API_ROUTES.REQUEST_OTP, {
      email: enteredEmail,
    });
    if (response?.data) {
      setOtpId(response?.data?.otp?._id);
      toast.success("OTP sent to your email");
    }
    setIsSending(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="auth-scree-wrapper">
        <div className="auth-scree-inner">
          <div className="auth-main-box">
            <h2>Password reset</h2>
            <p
              className="simple-link-text"
              style={{ textAlign: "left", paddingBottom: "24px" }}
            >
              A One-Time Password has been sent to your{" "}
              <span className="text-theme-color">{otpData?.email}</span>
              {/* <span className="text-theme-color">test@mailinator.cpom</span> */}
            </p>
            <div className="site-input" style={{ marginBottom: "10px" }}>
              <Controller
                name="code"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      placeholder={"Enter OTP Code"}
                      type="text"
                      {...field}
                      ref={field.ref}
                      errors={errors.code}
                    />
                  );
                }}
              />
            </div>
            <div style={{ marginBottom: "26px" }}>
              <p
                className="anchor-link-bottom"
                style={{
                  textAlign: "right",
                  paddingTop: "0px",
                }}
              >
                Didn't receive it? {/* eslint-disable-next-line */}
                <a
                  className="text-underline"
                  style={{ cursor: "pointer" }}
                  onClick={() => sendAgain()}
                >
                  send again
                  {isSending && <LoadingComponent />}
                </a>
              </p>
            </div>
            <div className="site-input">
              <Controller
                name="password"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      placeholder={"Enter new password"}
                      type="password"
                      {...field}
                      ref={field.ref}
                      errors={errors.password}
                      PasswordIcon={true}
                    />
                  );
                }}
              />
            </div>
            <div className="site-input">
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => {
                  return (
                    <InputField
                      placeholder={"Confirm new password"}
                      type="password"
                      {...field}
                      ref={field.ref}
                      errors={errors.confirmPassword}
                      PasswordIcon={true}
                    />
                  );
                }}
              />
            </div>
            <div className="site-input">
              <button className="site-btn">
                {isLoading && <LoadingComponent />}
                Confirm
              </button>
            </div>
          </div>
          <div className="anchor-link-bottom" >
            {/* eslint-disable-next-line */}
            <a onClick={() => navigate("/signin")} style={{ cursor: "pointer" }}>Back to login</a>
          </div>
        </div>
      </div>
    </form>
  );
}

export default PasswordReset;
