import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ProfileView from "../../pages/ProfileView";
const GoPremimumBanner = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleGoPremimum = () => {
    // navigate("/profile/?tab=plan");
    setShow(true);
  };
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 1000); // Simulate async loading
  }, []);
  return (
    <div style={{ minHeight: "100px" }}>
      {isVisible && (
        <div
          className="d-flex justify-content-center mt-5"
          style={{ minHeight: "100px" }}
        >
          <div className="premium-banner-container">
            <div className="premium-banner-content">
              <div className="premium-banner-content-mask">
                <p className="premium-banner-content-heading">Go premium</p>
                <p className="premium-banner-content-text">
                  No ads. Follow unlimited events.
                  <br /> Drag to organize in lf.to pages.
                </p>
              </div>
            </div>
            <div className="premium-banner-action">
              <button className="primary-button" onClick={handleGoPremimum}>
                Starting at only $2/mo
              </button>
            </div>
          </div>
          {show && (
            <ProfileView show={show} setShow={setShow} planShow={true} />
          )}
        </div>
      )}
    </div>
  );
};

export default GoPremimumBanner;
