import { jwtDecode } from "jwt-decode";
import { API_ROUTES } from "./config/apiRoutes";
import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const checkTokenExpiration = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Invalid token", error);
    return true;
  }
};

export const loginGuest = async (cb) => {
  // let isExpired = true;
  const userToken = localStorage.getItem("token");
  const sessionId = localStorage.getItem("sessionId");
  const user = localStorage.getItem("user");

  if (userToken) {
    return;
  }
  // if (userToken) {
  //   isExpired = checkTokenExpiration(userToken);
  // }
  // if ((isExpired || !sessionId) && !user) {
  //   const session_id = sessionId ?? uuidv4();
  //   const response = await axios.post(API_ROUTES.GUEST_LOGIN, {
  //     session_id,
  //   });
  //   if (response?.data) {
  //     localStorage.setItem("token", response.data?.token);
  //     if (!sessionId) {
  //       localStorage.setItem("sessionId", session_id);
  //     }
  //   }
  // } else {
  //   // const userToken = localStorage.getItem("token");
  // }

  if (!userToken || !user) {
    const session_id = sessionId ?? uuidv4();
    const response = await axios.post(API_ROUTES.GUEST_LOGIN, {
      session_id,
    });
    if (response?.data) {
      localStorage.setItem("token", response.data?.token);
      localStorage.setItem("user", JSON.stringify(response.data?.user));
      if (cb) {
        cb(response.data?.user, response.data?.token);
      }
      if (!sessionId) {
        localStorage.setItem("sessionId", session_id);
      }
    }
  }
};

export const getDate = (startDate, title) => {
  const currentDate = moment(); // Current date and time
  const startMoment = moment(startDate, "YYYY-MM-DD");

  const isFuture = startMoment.isAfter(currentDate);
  if (isFuture) {
    // const currentDate = moment().format("YYYY-MM-DD");
    let monthsDifference = startMoment.diff(currentDate, "months");
    let remainingDays = startMoment.diff(
      currentDate.add(monthsDifference, "months"),
      "days"
    );
    let result = "";
    if (monthsDifference >= 12) {
      const years = Math.floor(monthsDifference / 12); // Number of full years
      const monthsLeft = monthsDifference % 12; // Remaining months after calculating years
      result = `${years} year${years > 1 ? "s" : ""}`; // Add "s" for plural years
      if (monthsLeft > 0) {
        result += `, ${monthsLeft} month${monthsLeft > 1 ? "s" : ""}`;
      }
    } else if (monthsDifference >= 1) {
      result = `${monthsDifference} month${monthsDifference > 1 ? "s" : ""}`;
      if (remainingDays > 0) {
        result += `, ${remainingDays} day${remainingDays > 1 ? "s" : ""}`;
      }
    } else if (remainingDays > 0) {
      result = `${remainingDays} day${remainingDays > 1 ? "s" : ""}`;
    }
    return result;
  } else if ((startDate = "start_date" || !startDate)) return title;
};

export const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

const REPEAT_DAYS_MAP = {
  0: { value: 0, label: "Does not repeat" },
  1: { value: 1, label: "1 day" },
  2: { value: 7, label: "7 days" },
  3: { value: 30, label: "30 days" },
  4: { value: 360, label: "360 days" },
};

// Function to check if date is valid
function isValidDate(date) {
  return date && moment(date).isValid();
}

// Function to calculate next event date based on repeat days
export function calculateNextEventDate(startDate, repeatDays) {
  if (!isValidDate(startDate)) {
    return null;
  }

  if (repeatDays === 0) {
    return moment(startDate);
  }

  const currentDate = moment();
  const eventStartDate = moment(startDate);

  if (eventStartDate.isAfter(currentDate)) {
    return eventStartDate;
  }

  const daysToAdd = REPEAT_DAYS_MAP[repeatDays].value;
  let nextEventDate = moment(startDate);

  while (nextEventDate.isSameOrBefore(currentDate)) {
    nextEventDate.add(daysToAdd, "days");
  }

  return nextEventDate;
}

// Function to format time difference in a human-readable format
function formatTimeDifference(date) {
  if (!date) return "";

  const now = moment().startOf("day"); // This will be the current day at 00:00:00
  const targetDate = moment(date).startOf("day"); // Target date at 00:00:00
  const diffDays = targetDate.diff(now, "days");

  const years = Math.floor(Math.abs(diffDays) / 365);
  const months = Math.floor((Math.abs(diffDays) % 365) / 30);
  const days = Math.floor(Math.abs(diffDays) % 30);

  const parts = [];

  if (diffDays < 0) {
    if (years > 0) {
      parts.push(`${years} ${years === 1 ? "year" : "years"} ago`);
    }
    if (months > 0) {
      parts.push(`${months} ${months === 1 ? "month" : "months"} ago`);
    }
    if (days > 0 || parts.length === 0) {
      parts.push(
        `${days === 0 ? "Today" : days} ${days === 1 ? "day" : "days"} ago`
      );
    }
  } else {
    if (years > 0) {
      parts.push(`${years} ${years === 1 ? "year" : "years"}`);
    }
    if (months > 0) {
      parts.push(`${months} ${months === 1 ? "month" : "months"}`);
    }
    if (days > 0 || parts.length === 0) {
      parts.push(
        `${days === 0 ? "Today" : days} ${days === 1 ? "day" : "days"}`
      );
    }
  }

  return parts.join(" ");
}

function isFutureDate(date) {
  const currentDate = new Date();
  const checkDate = new Date(date);
  return checkDate > currentDate;
}

// Main function to process event data
export function processEventData(event) {
  if (!event) {
    console.warn("processEventData received undefined event");
    return {
      displayDate: "Unknown date",
      nextEventDate: null,
      timeUntilNext: "",
      cropped_image: null,
      title: "Unknown event",
      popularity: 0,
      categoryName: "",
    };
  }

  const {
    start_date,
    repeat_days,
    cropped_image,
    title,
    popularity,
    category,
    no_date_title,
  } = event;

  // Process date
  if (!isValidDate(start_date)) {
    return {
      ...event,
      displayDate: no_date_title,
      nextEventDate: null,
      timeUntilNext: "",
    };
  }

  let formattedDate = "";
  if (isFutureDate(start_date)) {
    formattedDate = formatTimeDifference(start_date);
  } else {
    const nextEventDate = calculateNextEventDate(start_date, repeat_days);
    formattedDate = formatTimeDifference(nextEventDate);
  }

  return {
    cropped_image,
    title,
    popularity,
    categoryName: category?.name || "",
    displayDate: formattedDate ? formattedDate : no_date_title,
  };
}

export const TAB_NAMES = {
  Discover: "Discover",
  Looking: "Looking Forward To",
};

export const feedTab = [
  { label: TAB_NAMES.Discover },
  {
    label: TAB_NAMES.Looking,
  },
];

export const INNER_TAB_NAMES = {
  username: "",
  feed: "NewsFeed",
  past: "Past",
  createEvent: "Create Event",
};

export const maskEmail = (email) => {
  const [local, domain] = email.split("@");
  const [domainPart, end] = domain.split(".");

  const maskedLocal = local.length > 4 ? local.slice(0, 4) + "***" : local;
  const maskedDomain = domainPart.slice(0, 1) + "***";
  const maskedEmail = `${maskedLocal}@${maskedDomain}.${end}`;

  return maskedEmail;
};

export const getUpcomingDate = (startDate, repeatDays) => {
  if (!isValidDate(startDate) || repeatDays === 0) {
    return startDate;
  }
  const date = calculateNextEventDate(startDate, repeatDays);

  return moment(date).format("YYYY-MM-DD");
};
