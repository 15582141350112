import React, { useState, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { IoChevronBack } from "react-icons/io5";

// Add these styles to match the design in your reference image
const cropperStyles = `
.ReactCrop__crop-selection {
  border: 2px dashed white !important;
  box-shadow: none !important;
}
`;

const ImageCropperPopup = ({
  imageUrl,
  crop,
  setCrop,
  onComplete,
  onClose,
  postShape,
  key,
}) => {
  const imgRef = useRef(null);
  const cropRef = useRef(null); // Reference to ReactCrop component
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [cropContainerSize, setCropContainerSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    let initialCrop = {};

    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.crossOrigin = "anonymous"; // Add this line for CORS
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setImageDimensions({ width, height });

        if (postShape === "2") {
          const size = Math.min(width, height);
          initialCrop = {
            x: (width - size) / 2,
            y: (height - size) / 2,
            width: size,
            height: size,
            aspect: 1,
          };
        } else if (postShape === "3") {
          const aspectRatio = 9 / 16;
          let cropWidth = width;
          let cropHeight = width / aspectRatio;
          if (cropHeight > height) {
            cropHeight = height;
            cropWidth = height * aspectRatio;
          }
          initialCrop = {
            x: (width - cropWidth) / 2,
            y: (height - cropHeight) / 2,
            width: cropWidth,
            height: cropHeight,
            aspect: aspectRatio,
          };
        }
        setCrop(initialCrop);
        // Also set completedCrop to match the initial crop
        setCompletedCrop(initialCrop);
      };
    }

    return () => {
      setCrop(null);
    };
  }, [postShape, imageUrl, setCrop, key]);

  // Sync completedCrop with crop whenever crop changes
  useEffect(() => {
    if (crop) {
      setCompletedCrop(crop);
    }
  }, [crop]);

  useEffect(() => {
    if (cropRef.current) {
      const rect = cropRef.current.getBoundingClientRect();
      setCropContainerSize({ width: rect.width, height: rect.height });
    }
  }, [cropRef.current]);

  const handleCropChange = (newCrop) => {
    if (!crop || !newCrop || !imageDimensions.width || !imageDimensions.height)
      return;

    let constrainedCrop = { ...newCrop };

    if (constrainedCrop.x < 0) constrainedCrop.x = 0;
    if (constrainedCrop.y < 0) constrainedCrop.y = 0;
    if (constrainedCrop.x + constrainedCrop.width > imageDimensions.width) {
      constrainedCrop.width = imageDimensions.width - constrainedCrop.x;
    }
    if (constrainedCrop.y + constrainedCrop.height > imageDimensions.height) {
      constrainedCrop.height = imageDimensions.height - constrainedCrop.y;
    }

    if (constrainedCrop.width <= 0) constrainedCrop.width = 1;
    if (constrainedCrop.height <= 0) constrainedCrop.height = 1;

    setCrop(constrainedCrop);
    // Also update completedCrop when crop changes
    setCompletedCrop(constrainedCrop);
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const handleCroppedImageUpload = () => {
    if (!imgRef.current || !imageUrl) return;

    // Use either completedCrop or crop, whichever is available
    const cropToUse = completedCrop || crop;

    if (!cropToUse) {
      console.error("No crop selection available");
      return;
    }

    try {
      const image = imgRef.current;
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = cropToUse.width;
      canvas.height = cropToUse.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        cropToUse.x * scaleX,
        cropToUse.y * scaleY,
        cropToUse.width * scaleX,
        cropToUse.height * scaleY,
        0,
        0,
        cropToUse.width,
        cropToUse.height
      );

      canvas.toBlob((blob) => {
        if (blob) {
          const reader = new FileReader();
          reader.onloadend = () => {
            onComplete(reader.result);
            onClose();
          };
          reader.readAsDataURL(blob);
        }
      }, "image/jpeg");
    } catch (error) {
      console.error("Error cropping image:", error);
      // Fallback if canvas is tainted
      alert(
        "There was an issue processing this image. Please try another one."
      );
    }
  };

  return (
    <>
      {/* Add styles for dashed crop border */}
      <style>{cropperStyles}</style>

      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(34, 34, 34)",
            padding: "20px",
            borderRadius: "12px",
            width: "90%",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <button
              onClick={onClose}
              style={{
                background: "none",
                border: "none",
                color: "white",
                fontSize: "24px",
                cursor: "pointer",
                marginLeft: "-10px",
                marginTop: "-12px",
              }}
            >
              <IoChevronBack />
            </button>
            <h3
              style={{
                color: "white",
                flexGrow: 1,
              }}
            >
              Crop
            </h3>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              ref={cropRef}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <ReactCrop
                src={imageUrl}
                crop={crop}
                onChange={handleCropChange}
                onComplete={handleCropComplete}
                locked={true}
              >
                <img
                  ref={imgRef}
                  src={imageUrl}
                  alt="Preview"
                  crossOrigin="anonymous" // Add this line for CORS
                  style={{
                    display: "block", // This line centers the image
                    margin: "0 auto", // This line centers the image
                    maxWidth: "100%",
                    maxHeight: "calc(80vh - 100px)",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                />
              </ReactCrop>
            </div>
          </div>

          <button
            onClick={handleCroppedImageUpload}
            style={{
              width: "100%",
              padding: "12px 0",
              backgroundColor: "#FFA467",
              color: "white",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              fontWeight: "bold",
              fontSize: "16px",
              transition: "background 0.3s",
              marginTop: "15px",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#FF9040")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#FFA467")}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default ImageCropperPopup;
