import React, { useMemo, useState } from "react";
import barIcon from "../images/bar-lines.png";
import { useLocation, useNavigate } from "react-router-dom";
import DesktopHamBurgerItems from "./DesktopHamBurgerItems";
import MobilehamburgerItem from "./MobilehamburgerItem";
import { API_ROUTES } from "../config/apiRoutes";
import axiosInstance from "../axiosInstance";
import { LOGIN_TYPE } from "../constant";
import { useGlobalState } from "../store/store";
import { useDispatch } from "react-redux";
import { logout } from "../slices/auth-slice";
import { loginGuest } from "../helper";

const Header = ({ isPreimum }) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const user = JSON.parse(localStorage.getItem("user" || "{}"));
  const isLoggedIn = useMemo(
    () =>
      user?.login_type === "email" ||
      user?.login_type === "apple" ||
      user?.login_type === "google",
    [user]
  );
  const state = useGlobalState();

  const navigate = useNavigate();
  // const state = useGlobalState();

  const handleLogout = async () => {
    await axiosInstance.post(API_ROUTES.LOGOUT);
    setIsActive(false);
    // removeCookie(USER);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch(logout());
    state.removePublicEvents();
    state.removeCategories();
    // await loginGuest();
  };
  const showHeader = useMemo(() => {
    if (location.pathname === "/tos" || location.pathname === "/privacy") {
      return true;
    }
    if (
      location.pathname === `/${user?.username}` &&
      LOGIN_TYPE.includes(user?.login_type)
    ) {
      return false;
    } else if (
      location.pathname !== `/${user?.username}` &&
      location.pathname !== "/"
    ) {
      return false;
    }
    return true;
  }, [location.pathname, user]);

  return (
    showHeader && (
      <header className="site-header">
        <div className="container-fluid">
          <div className="header-inner">
            <div className="header-left">
              <p onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <img
                  src="../images/logo.png"
                  alt="logo"
                  height={50}
                  width={114}
                />
              </p>
            </div>
            {/* <div className="header-middle">
							<ul>
								<li>
									<p
										onClick={() => {
											setIsActive(false);
											window.open("https://joinlft.com/contact", "_blank");
										}}
									>
										Contact
									</p>
								</li>
								<li>
									<p
										onClick={() => {
											setIsActive(false);
											window.open("https://joinlft.com/faq", "_blank");
										}}
									>
										FAQ
									</p>
								</li>
								<li>
									<p
										onClick={() => {
											setIsActive(false);
											window.open("https://joinlft.com/", "_blank");
										}}
									>
										About
									</p>
								</li>
							</ul>
						</div> */}
            {/* <div
            className="header-right desktop-header"
            style={{ position: "relative" }}
          > */}
            <div className=" desktop-header" style={{ position: "relative" }}>
              <div
                className="header-mobile-toggle"
                style={{ cursor: "pointer" }}
              >
                <img width={25} height={25} src={barIcon} alt="icon" />
              </div>
              <DesktopHamBurgerItems
                isLoggedIn={isLoggedIn}
                handleLogout={handleLogout}
                setIsActive={setIsActive}
                isActive={isActive}
                isPreimum={isPreimum}
              />

              {/* <ul className={`${isActive ? "active" : ""}`}>
              <li>
                <a href="#">iOS app</a>
              </li>
              {isLoggedIn && (
                <div className="header-mobile-toggle" onClick={toggleClass}>
                  <img width={25} height={25} src={barIcon} alt="icon" />
                </div>
              )}
              {!isLoggedIn && (
                <li>
                  <Link to={"signin"}>Sign in </Link>
                </li>
              )}
              <li className="d-none">
                <a href="#">Contact</a>
              </li>
              <li className="d-none">
                <a href="#">FAQ</a>
              </li>
              <li className="d-none">
                <a href="#">About</a>
              </li>
              <li><a href="#"><img width={40} height={40} src={SettingIcon} alt="icon" /></a></li>
            </ul> */}
            </div>

            <div className="mobile-header">
              <div className="header-right" style={{ position: "relative" }}>
                <MobilehamburgerItem
                  isLoggedIn={isLoggedIn}
                  handleLogout={handleLogout}
                  setIsActive={setIsActive}
                  isActive={isActive}
                  isPreimum={isPreimum}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  );
};

export default Header;
