import { BASE_URL } from "../constant";

export const API_ROUTES = {
  GUEST_LOGIN: `${BASE_URL}/login/guest`,
  CATEGORIES: `${BASE_URL}/category/get`,
  PUBLIC_EVENTS: `${BASE_URL}/event/get_public`,
  PRIVATE_EVENTS: `${BASE_URL}/event/get_private`,
  SIGN_UP: `${BASE_URL}/create/email`,
  LOGIN_EMAIL: `${BASE_URL}/login/email`,
  LOGIN_APPLE: `${BASE_URL}/login/web/apple`,
  LOGIN_GOOGLE: `${BASE_URL}/login/web/google`,
  LOGOUT: `${BASE_URL}/users/logout`,
  EVENT_NEWS: `${BASE_URL}/news/get`,
  FOLLOW_EVENT: `${BASE_URL}/event/accept`,
  UN_FOLLOW_EVENT: `${BASE_URL}/event/remove_accepted`,
  DELETE_EVENT: `${BASE_URL}/event/delete`,
  SAVE_CUSTOM_EVENT_ORDER: `${BASE_URL}/event/saveCustomOrder`,
  GET_USER_DATA: `${BASE_URL}/users/getUserData`,
  GET_ALL_NEWS: `${BASE_URL}/news/all`,
  REQUEST_OTP: `${BASE_URL}/auth/requestOTP`,
  RESET_PASSWORD: `${BASE_URL}/auth/resetPassword`,
  UPDATE_AVATAR: `${BASE_URL}/users/updateAvatar`,
  UPDATE_USER: `${BASE_URL}/users/update`,
  DELETE_USER: `${BASE_URL}/users/delete`,
  PROFILE_BY_USER_NAME: `${BASE_URL}/users/profile`,
  VERIFY_OTP: `${BASE_URL}/auth/verifyOTP`,
  CREATE_SUBSCRIPTION: `${BASE_URL}/stripe/create_subscription`,
  UPDATE_SUBSCRIPTION: `${BASE_URL}/stripe/update_subscription`,
  CANCEL_SUBSCRIPTION: `${BASE_URL}/stripe/cancel_subscription`,
  UPDATE_PAYMENT_METHOD: `${BASE_URL}/stripe/updatePaymentMethod`,
  LIFETIME_SUBSCRIPTION: `${BASE_URL}/stripe/one_time`,
  CREATE_SUBSCRIPTION_CHECKOUT: `${BASE_URL}/stripe/create-checkout-session`,
  UPDATE_SESSION_CHECKOUT: `${BASE_URL}/stripe/create-update-session`,
  COMPLETE_SESSION_UPDATE: `${BASE_URL}/stripe/complete-update`,
  GET_PRIVATE_EVENTS: `${BASE_URL}/event/get_private`,
  CREATE_EVENT: `${BASE_URL}/event/create`,
  UPLOAD_SINGLE: `${BASE_URL}/upload/uploadSingle`,
  SLUGS: `${BASE_URL}/slugs/sitemap.xml`,
};
