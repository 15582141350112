import { useNavigate } from "react-router-dom";

const WelcomeModal = ({ toggleWelcomeModal }) => {
  const navigate = useNavigate();
  const handleSignupClick = () => {
    navigate("/signup");
  };
  return (
    <div className="welcome-modal-container">
      <div className="welcome-modal-content-container">
        <div className="logo">
          <img src="../images/logo.png" alt="logo" height={50} />
        </div>
        <h4 className="my-3">Welcome 👋</h4>
        <p>
          <span className="clr-primary">lft - looking forward to</span> is a
          platform where you can discover things to look forward to like movies,
          shows, games or your own personal milestones
        </p>
        <br />
        <p>
          Sign up for free to save your events between devices and share your
          personal looking forward to page, or start using the platform without
          an account.
        </p>
        <button
          className="welcome-modal-button w-100"
          onClick={handleSignupClick}
        >
          Sign Up
        </button>
        <p className="cursor-pointer" onClick={toggleWelcomeModal}>
          Use without an account
        </p>
      </div>
    </div>
  );
};

export default WelcomeModal;
