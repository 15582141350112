import React from "react";

const CancelIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_479_422)">
        <rect
          x="0.5"
          y="0.256836"
          width="20.9706"
          height="20.9706"
          rx="10.4853"
          fill="black"
          fillOpacity="0.3"
        />
        <path
          d="M6.74264 6.49955L15.2279 14.9848M6.74264 14.9848L15.2279 6.49955"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_479_422"
          x="-9.5"
          y="-9.74316"
          width="40.9706"
          height="40.9707"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_479_422"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_479_422"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CancelIcon;
