export const BASE_URL = "https://api.lf.to";
// export const BASE_URL = "http://localhost:4000";
export const APP_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://lookingforwardto.com";
export const LOGIN_TYPE = ["email", "apple", "google"];
export const PASSWORD = {
  REQUEST: "request",
  RESET: "reset",
};
export const EVENT_PAGE_SIZE = 1090;
export const PRICE_IDS = {
  monthly: "price_1QmspWAcryl3M8APdW0YhKSy",
  yearly: "price_1QmspWAcryl3M8APyti7HotV",
  lifetime: "price_1QmspWAcryl3M8APK1obWAqy",
};
export const PLANS = [
  {
    priceId: PRICE_IDS.monthly,
    name: "Monthly",
    price: "$2",
  },
  {
    priceId: PRICE_IDS.yearly,
    name: "Annual",
    price: "$10",
    discount: "Save 55%",
  },
  {
    priceId: PRICE_IDS.lifetime,
    name: "Life	time",
    price: "$20",
  },
];

export const STRIPE_CALLBACK_MESSAGES = {
  payment_success: "payment success",
  payment_failed: "payment failed",
  pm_updated: "payment method updated",
  pm_update_failed: "payment method update failed",
};
