// ProtectedRoute.js
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, isVerified, user }) => {
  if (!user) {
    // Redirect to Signin if the user is not logged in
    return <Navigate to="/signin" replace />;
  }

  if (!isVerified) {
    // Redirect to the verification page if the user is not verified
    return <Navigate to="/verify-otp" replace />;
  }

  // Allow access to the route if the user is logged in and verified
  return children;
};

export default ProtectedRoute;
