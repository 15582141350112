import ScrollToTopOnMount from "../utils/ScrollTop";

const ToS = () => {
	return (
		<div className="container py-4">
			<ScrollToTopOnMount />
			<h1 className="my-2">
				<span>Terms of Service</span>
			</h1>
			<p>
				<span>Welcome to </span>
				<span>LookingForwardTo.com</span>
				<span>&nbsp;(the &ldquo;Platform&rdquo;), owned and operated by </span>
				<span>Slowdown Digital LLC</span>
				<span>
					, a Wyoming company. These Terms of Service (the &ldquo;Terms&rdquo;)
					govern your access to and use of the Platform, including our website,
					mobile applications, and any related services. By accessing or using
					the Platform, you agree to be bound by these Terms. If you do not
					agree with any part of these Terms, you must not use the Platform.
				</span>
			</p>
			<h2 className="my-4">
				<span>1. Introduction &amp; Acceptance</span>
			</h2>
			<p>
				<span>1.1 </span>
				<span>
					About the Platform
					<br />
				</span>
				<span>
					&nbsp;LookingForwardTo.com (&ldquo;we,&rdquo; &ldquo;us,&rdquo; or
					&ldquo;our&rdquo;) provides tools for discovering, tracking, and
					sharing upcoming events. These may include movies, shows, games,
					sports, and personal milestones. The Platform may also offer related
					content, such as news feeds, event details, user-generated pages, and
					a short-link service at lf.to.
				</span>
			</p>
			<p>
				<span>1.2 </span>
				<span>
					Acceptance of Terms
					<br />
				</span>
				<span>
					&nbsp;By creating an account, using the Platform, or otherwise
					indicating your acceptance, you acknowledge that you have read,
					understood, and agree to be bound by these Terms and any additional
					policies referenced herein, including our Privacy Policy. If you do
					not agree, do not use the Platform.
				</span>
			</p>
			<h2 className="my-4">
				<span>2. Company Information</span>
			</h2>
			<ul>
				<li>
					<span>Owner/Operator</span>
					<span>: Slowdown Digital LLC (a Wyoming company)</span>
				</li>
				<li>
					<span>Business Address</span>
					<span>: 442 Broadway Street, New York, NY 10013</span>
				</li>
				<li>
					<span>Contact Email</span>
					<span>: hello@joinlft.com</span>
				</li>
			</ul>
			<h2 className="my-4">
				<span>3. Eligibility</span>
			</h2>
			<p>
				<span>3.1 </span>
				<span>
					Age Requirement
					<br />
				</span>
				<span>
					&nbsp;You must be at least the age of majority in your jurisdiction
					(or have the permission of a legal guardian) to use the Platform. If
					you are under the age of majority, you represent that your legal
					guardian has reviewed and agreed to these Terms on your behalf.
				</span>
			</p>
			<p>
				<span>3.2 </span>
				<span>
					Compliance with Laws
					<br />
				</span>
				<span>
					&nbsp;You are responsible for ensuring that your use of the Platform
					complies with all applicable laws, rules, and regulations.
				</span>
			</p>
			<h2 className="my-4">
				<span>4. Account Registration &amp; Security</span>
			</h2>
			<p>
				<span>4.1 </span>
				<span>
					Registration
					<br />
				</span>
				<span>
					&nbsp;To access certain features, you may be required to create an
					account by providing a username, email address, password, and other
					required information. You agree to provide accurate, current, and
					complete information during registration, and to update such
					information as needed.
				</span>
			</p>
			<p>
				<span>4.2 </span>
				<span>
					Account Responsibility
					<br />
				</span>
				<span>
					&nbsp;You are responsible for maintaining the confidentiality of your
					login credentials and for all activities that occur under your
					account. You agree to notify us immediately of any unauthorized access
					or use of your account. We are not liable for any loss or damage
					arising from your failure to protect your credentials.
				</span>
			</p>
			<p>
				<span>4.3 </span>
				<span>
					Termination or Suspension
					<br />
				</span>
				<span>
					&nbsp;We reserve the right to suspend or terminate your account at any
					time, for any reason (including violation of these Terms), without
					notice and without liability to you.
				</span>
			</p>
			<h2 className="my-4">
				<span>5. Use of the Platform</span>
			</h2>
			<p>
				<span>5.1 </span>
				<span>
					Personal, Non-Commercial Use
					<br />
				</span>
				<span>
					&nbsp;Unless otherwise expressly authorized, the Platform is for your
					personal, non-commercial use. You may not reproduce, duplicate, copy,
					sell, resell, or exploit any portion of the Platform without our
					express written permission.
				</span>
			</p>
			<p>
				<span>5.2 </span>
				<span>
					Prohibited Conduct
					<br />
				</span>
				<span>&nbsp;You agree not to:</span>
			</p>
			<ul>
				<li>
					<span>
						Violate any laws or regulations in connection with your use of the
						Platform.
					</span>
				</li>
				<li>
					<span>
						Post or share content that is unlawful, defamatory, obscene,
						harassing, hateful, infringing, or otherwise objectionable.
					</span>
				</li>
				<li>
					<span>
						Infringe upon the intellectual property or privacy rights of others.
					</span>
				</li>
				<li>
					<span>
						Use automated means (e.g., bots, scrapers) to access, collect, or
						mine data from the Platform without our prior written permission.
					</span>
				</li>
				<li>
					<span>
						Introduce viruses or other harmful code that disrupts or interferes
						with the Platform&rsquo;s operation.
					</span>
				</li>
				<li>
					<span>
						Attempt to gain unauthorized access to other users&rsquo; accounts
						or the Platform&rsquo;s systems.
					</span>
				</li>
			</ul>
			<p>
				<span>5.3 </span>
				<span>
					User-Generated Content
					<br />
				</span>
				<span>
					&nbsp;Users may create or follow events, share links (lf.to), or
					otherwise interact on the Platform. You are solely responsible for any
					content you submit, post, or display on the Platform (&ldquo;User
					Content&rdquo;). You represent that you have the rights to such
					content and that it does not violate any third-party rights or these
					Terms.
				</span>
			</p>
			<p>
				<span>5.4 </span>
				<span>
					Content Moderation
					<br />
				</span>
				<span>
					&nbsp;We reserve the right, but have no obligation, to monitor, edit,
					or remove any User Content that we deem, in our sole discretion, to be
					in violation of these Terms or otherwise objectionable.
				</span>
			</p>
			<h2 className="my-4">
				<span>6. Paid Subscriptions &amp; Memberships</span>
			</h2>
			<p>
				<span>6.1 </span>
				<span>
					Subscription Plans
					<br />
				</span>
				<span>
					&nbsp;We may offer paid subscriptions or memberships (such as monthly,
					yearly, or lifetime plans) that provide access to additional features,
					such as an ad-free experience or unlimited event tracking. The
					specific terms, fees, and features will be described at the time of
					purchase.
				</span>
			</p>
			<p>
				<span>6.2 </span>
				<span>
					Billing &amp; Payment
					<br />
				</span>
				<span>
					&nbsp;By providing a payment method, you represent and warrant that
					you are authorized to use it. You agree to pay all applicable fees
					associated with your subscription (including any taxes). Fees are
					non-refundable unless required by law or stated otherwise.
				</span>
			</p>
			<p>
				<span>6.3 </span>
				<span>
					Cancellations &amp; Refunds
					<br />
				</span>
				<span>
					&nbsp;You may cancel your subscription according to the instructions
					provided on the Platform or by contacting us at hello@joinlft.com.
					Unless otherwise stated, cancellations will take effect at the end of
					the current billing cycle.
				</span>
			</p>
			<p>
				<span>6.4 </span>
				<span>
					Changes to Pricing &amp; Plans
					<br />
				</span>
				<span>
					&nbsp;We reserve the right to change our subscription plans or adjust
					pricing at any time. Any changes will be communicated to you in
					advance, and you will have the option to cancel if you do not agree to
					the revised terms.
				</span>
			</p>
			<h2 className="my-4">
				<span>7. Intellectual Property Rights</span>
			</h2>
			<p>
				<span>7.1 </span>
				<span>
					Ownership
					<br />
				</span>
				<span>
					&nbsp;All software, code, text, images, trademarks, logos, and other
					content (excluding User Content) provided by or on behalf of Slowdown
					Digital LLC via the Platform are owned or licensed by us. You may not
					use, reproduce, or distribute such materials without our prior written
					consent.
				</span>
			</p>
			<p>
				<span>7.2 </span>
				<span>
					License to User Content
					<br />
				</span>
				<span>
					&nbsp;By submitting or posting User Content on the Platform, you grant
					us a non-exclusive, worldwide, royalty-free license to use, display,
					reproduce, distribute, and modify your User Content for the purpose of
					operating, promoting, and improving the Platform. We do not claim
					ownership of your User Content.
				</span>
			</p>
			<h2 className="my-4">
				<span>8. Third-Party Links &amp; Services</span>
			</h2>
			<p>
				<span>
					The Platform may contain links to third-party websites or services
					that are not owned or controlled by Slowdown Digital LLC. We do not
					endorse or assume any responsibility for such third-party content or
					practices. Your use of any third-party service is at your own risk.
				</span>
			</p>
			<h2 className="my-4">
				<span>9. Disclaimers &amp; Limitation of Liability</span>
			</h2>
			<p>
				<span>9.1 </span>
				<span>
					&ldquo;As Is&rdquo; Basis
					<br />
				</span>
				<span>
					&nbsp;The Platform and all related services are provided on an
					&ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. We make no
					warranties, express or implied, regarding the operation, accuracy,
					reliability, or availability of the Platform.
				</span>
			</p>
			<p>
				<span>9.2 </span>
				<span>
					Event Information &amp; User Content
					<br />
				</span>
				<span>
					&nbsp;We strive to provide accurate information about upcoming events
					and user-generated content, but we do not guarantee its correctness or
					completeness. We are not responsible for any errors, omissions, or
					outcomes arising from reliance on such information.
				</span>
			</p>
			<p>
				<span>9.3 </span>
				<span>
					Limitation of Liability
					<br />
				</span>
				<span>
					&nbsp;To the fullest extent permitted by law, Slowdown Digital LLC,
					its officers, directors, employees, and agents shall not be liable for
					any indirect, incidental, special, consequential, or punitive damages,
					or any loss of profits or revenues, whether incurred directly or
					indirectly, or any loss of data, use, or goodwill, resulting from:
				</span>
			</p>
			<ul>
				<li>
					<span>
						Your access to or use of (or inability to access or use) the
						Platform.
					</span>
				</li>
				<li>
					<span>
						Any conduct or content of any third party on the Platform.
					</span>
				</li>
				<li>
					<span>
						Unauthorized access, use, or alteration of your content or data.
					</span>
				</li>
				<li>
					<span>Any other matter relating to the Platform.</span>
				</li>
			</ul>
			<h2 className="my-4">
				<span>10. Indemnification</span>
			</h2>
			<p>
				<span>
					You agree to indemnify and hold harmless Slowdown Digital LLC, its
					affiliates, officers, agents, and employees from and against any and
					all claims, liabilities, damages, losses, or expenses (including
					reasonable attorneys&rsquo; fees) arising out of or in any way
					connected with your use of the Platform, your User Content, or your
					violation of these Terms.
				</span>
			</p>
			<h2 className="my-4">
				<span>11. Changes to These Terms</span>
			</h2>
			<p>
				<span>
					We may update these Terms from time to time. If we make material
					changes, we will notify you by posting the revised Terms on the
					Platform or through other appropriate communication channels. Your
					continued use of the Platform after any such update constitutes your
					acceptance of the new Terms.
				</span>
			</p>
			<h2 className="my-4">
				<span>12. Governing Law &amp; Dispute Resolution</span>
			</h2>
			<p>
				<span>
					These Terms shall be governed by and construed in accordance with the
					laws of the State of Wyoming, without regard to its conflict of laws
					principles. Any dispute arising out of these Terms or your use of the
					Platform shall be resolved through binding arbitration in the State of
					Wyoming, unless otherwise required by law. You agree to submit to the
					personal jurisdiction of such arbitrations.
				</span>
			</p>
			<h2 className="my-4">
				<span>13. Miscellaneous</span>
			</h2>
			<p>
				<span>13.1 </span>
				<span>
					Entire Agreement
					<br />
				</span>
				<span>
					&nbsp;These Terms, together with any policies referenced herein
					(including our Privacy Policy), constitute the entire agreement
					between you and Slowdown Digital LLC regarding your use of the
					Platform and supersede any prior agreements.
				</span>
			</p>
			<p>
				<span>13.2 </span>
				<span>
					Severability
					<br />
				</span>
				<span>
					&nbsp;If any provision of these Terms is held invalid or
					unenforceable, the remaining provisions shall remain in full force and
					effect.
				</span>
			</p>
			<p>
				<span>13.3 </span>
				<span>
					No Waiver
					<br />
				</span>
				<span>
					&nbsp;The failure of either party to enforce any right or provision
					under these Terms will not constitute a waiver of such right or
					provision.
				</span>
			</p>
			<p>
				<span>13.4 </span>
				<span>
					Assignment
					<br />
				</span>
				<span>
					&nbsp;You may not assign or transfer your rights or obligations under
					these Terms without our prior written consent. We may freely assign
					these Terms as part of a merger, acquisition, or other transfer of our
					assets.
				</span>
			</p>
			<hr />
			<p>
				<span></span>
			</p>
			<p>
				<span>
					Contact Information
					<br />
				</span>
				<span>
					&nbsp;If you have any questions or concerns about these Terms, please
					contact us at:
				</span>
			</p>
			<ul>
				<li>
					<span>Slowdown Digital LLC</span>
				</li>
				<li>
					<span>442 Broadway Street, New York, NY 10013</span>
				</li>
				<li>
					<span>hello@joinlft.com</span>
				</li>
			</ul>
			<p>
				<span>Thank you for using LookingForwardTo.com!</span>
			</p>
			<p>
				<span></span>
			</p>
		</div>
	);
};

export default ToS;
