import React, { useState } from "react";
import barIcon from "../images/bar-lines.png";
import { useNavigate } from "react-router-dom";
import UserIcon from "./svgs/User";
import CrownIcon from "./svgs/Crown";
import ProfileView from "../pages/ProfileView";
const DesktopHamBurgerItems = ({
  isLoggedIn,
  handleLogout,
  setIsActive,
  isActive,
  isPreimum,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [planShow, setPlanShow] = useState(false);
  return (
    <>
      <ul className="desktop-header">
        {/* <li>
          <p>iOS app</p>
        </li> */}
        <div className="d-flex">
          {!isPreimum && (
            <div
              className="me-3"
              onClick={() => {
                setPlanShow(true);
                // navigate("/profile/?tab=plan");
                setShow(true);
              }}
            >
              <CrownIcon />
            </div>
          )}
          <div
            onClick={() => setIsActive(!isActive)}
            style={{ cursor: "pointer" }}
          >
            {isLoggedIn ? (
              <img width={25} height={25} src={barIcon} alt="icon" />
            ) : (
              <UserIcon />
            )}
          </div>
        </div>

        {/* {!isLoggedIn ? (
					<li>
						<p
							onClick={() => {
								setIsActive(false);
								navigate("/signin");
							}}
						>
							<UserIcon />
						</p>
					</li>
				) : (
					""
				)} */}
        {/* <li className="d-none">
					<p
						onClick={() => {
							setIsActive(false);
							navigate("/contact");
						}}
					>
						Contact
					</p>
				</li>
				<li className="d-none">
					<p
						onClick={() => {
							setIsActive(false);
							window.open("https://joinlft.com/faq", "_blank");
						}}
					>
						FAQ
					</p>
				</li>
				<li className="d-none">
					<p
						onClick={() => {
							setIsActive(false);
							window.open("https://joinlft.com/", "_blank");
						}}
					>
						About
					</p>
				</li> */}

        {/* <li><p ><img width={40} height={40} src={SettingIcon} alt="icon" /></p></li> */}
      </ul>
      {isActive && (
        <div className={`${isActive ? "hamburger-active-desktop" : ""} `}>
          {isLoggedIn ? (
            <ul>
              <li>
                <p
                  onClick={() => {
                    // navigate("/profile");
                    setShow(true);
                    setPlanShow(false);
                    setIsActive(false);
                  }}
                >
                  Profile
                </p>
              </li>
              <li>
                <p
                  onClick={() => {
                    setIsActive(false);
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSeskAoXg8V3Mjn1xcGXRpnW7-tG7rsnAC4NFidKpqSLF-FmKw/viewform",
                      "_blank"
                    );
                  }}
                >
                  Feedback
                </p>
              </li>
              <li>
                <p onClick={handleLogout}>Logout</p>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <p
                  onClick={() => {
                    navigate("/signin");
                    setIsActive(false);
                  }}
                >
                  Sign In
                </p>
              </li>

              <li>
                <p
                  onClick={() => {
                    navigate("/signup");
                    setIsActive(false);
                  }}
                >
                  Sign Up
                </p>
              </li>
            </ul>
          )}
        </div>
      )}
      {show && (
        <ProfileView
          isPreimum={isPreimum}
          show={show}
          setShow={setShow}
          planShow={planShow}
        />
      )}
    </>
  );
};

export default DesktopHamBurgerItems;
