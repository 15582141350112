import React, { useMemo, useCallback, useState, useEffect } from "react";
import ModalBox from "./ModalBox";
import axiosInstance from "../axiosInstance";
import { API_ROUTES } from "../config/apiRoutes";
import moment from "moment";
import LoadingComponent from "./LoadingComponent";
import { useDispatch } from "react-redux";
import { setUserData } from "../slices/auth-slice";

const Event = ({
  isLoading,
  setEventId,
  event,
  eventTitleBox,
  eventId,
  setUser,
  videos,
}) => {
  const dispatch = useDispatch();
  const [isApiCalling, setIsApiCalling] = useState(false);

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const isFollowed = user?.accepted_events?.some((eve) => eve._id === eventId);

  // Initialize with isFollowed and use useEffect to keep in sync
  const [localIsFollowed, setLocalIsFollowed] = useState(false);
  const [activeTab, setActiveTab] = useState("news"); // Default tab is "news"

  // Use useEffect to properly set the initial state after component mounts
  useEffect(() => {
    setLocalIsFollowed(isFollowed);
  }, [isFollowed, eventId]);

  const handleFollow = useCallback(
    async (eventId) => {
      setIsApiCalling(true);
      try {
        const response = await axiosInstance.post(
          localIsFollowed
            ? API_ROUTES.UN_FOLLOW_EVENT
            : API_ROUTES.FOLLOW_EVENT,
          { id: eventId }
        );

        if (response?.data?.user) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          dispatch(setUserData(response.data.user));
          if (setUser) {
            let updatedUser = response.data.user;
            if (updatedUser?.accepted_events?.length) {
              const sortedEvents = updatedUser.accepted_events.sort(
                (a, b) => new Date(a.start_date) - new Date(b.start_date)
              );
              updatedUser = { ...updatedUser, accepted_events: sortedEvents };
            }
            setUser(updatedUser);
            setEventId(null);
          }

          setLocalIsFollowed(!localIsFollowed);
        }
      } catch (error) {
        console.error("Error following/unfollowing event:", error);
      } finally {
        setIsApiCalling(false);
      }
    },
    [eventId, localIsFollowed, setUser, setEventId]
  );

  return (
    <ModalBox
      isLoading={isLoading}
      handleClose={() => setEventId(null)}
      eventId={eventId}
      Header={
        <div>
          <div
            className="modal-header-section align-items-center justify-content-between"
            style={{ paddingTop: "50px" }}
          >
            <div className="modal-header-title-date align-items-end">
              <h1>{eventTitleBox?.title}</h1>
              {eventTitleBox?.start_date &&
              moment(eventTitleBox.start_date).isValid() ? (
                <small>
                  {moment(eventTitleBox?.start_date).format("MMMM D,YYYY")}
                </small>
              ) : (
                <small>
                  {eventTitleBox?.no_date_title ||
                  eventTitleBox?.duration != null
                    ? String(
                        eventTitleBox?.no_date_title || eventTitleBox?.duration
                      )
                    : "Date/Duration Unavailable"}
                </small>
              )}
            </div>
            <div className="pe-4">
              {isApiCalling ? (
                <LoadingComponent />
              ) : (
                <button onClick={() => handleFollow(eventId)}>
                  {localIsFollowed ? "UnFollow" : "Follow"}
                </button>
              )}
            </div>
          </div>
        </div>
      }
      Body={
        <div>
          {eventTitleBox?.news_description && (
            <h2
              style={{
                fontSize: "20px",
                color: "white",
                lineHeight: "23.8px",
                wordWrap: "break-word",
              }}
            >
              {eventTitleBox.news_description}
            </h2>
          )}
          {/* Tab Navigation */}
          <div className="newsfeed-tabs">
            <button
              className={activeTab === "news" ? "active" : ""}
              onClick={() => setActiveTab("news")}
            >
              Newsfeed
            </button>
            <button
              className={activeTab === "media" ? "active" : ""}
              onClick={() => setActiveTab("media")}
            >
              Media
            </button>
          </div>

          {/* Newsfeed Section */}
          {activeTab === "news" && (
            <>
              {event?.results?.length > 0 ? (
                <div className="newsfeed-body">
                  {event.results.map((newsItem) => (
                    <ul key={newsItem?._id}>
                      <li
                        onClick={() => window.open(newsItem?.url, "_blank")}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="in-left">
                          {newsItem?.image && (
                            <img
                              src={newsItem.image}
                              alt={newsItem.title}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.style.display = "none";
                              }}
                            />
                          )}
                        </div>
                        <div className="in-right">
                          <h5>{newsItem?.source?.title || "Unknown Source"}</h5>
                          <h3>{newsItem?.title}</h3>
                          <p className="line-clamp-2">{newsItem?.body}</p>
                          <span className="span-text">
                            {moment(newsItem?.dateTimePub).fromNow()}
                          </span>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              ) : (
                <p>No News Available</p>
              )}
            </>
          )}

          {/* Media Section */}
          {activeTab === "media" && (
            <>
              {videos?.length > 0 ? (
                <div className="video-section">
                  {videos.map((videoUrl, index) => {
                    const videoId = new URL(videoUrl).searchParams.get("v");
                    return (
                      <iframe
                        key={index}
                        width="100%"
                        height="315"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    );
                  })}
                </div>
              ) : (
                <p>No Videos Available</p>
              )}
            </>
          )}
        </div>
      }
    />
  );
};
export default Event;
