import React from "react";
import barIcon from "../images/bar-lines.png";
import { useNavigate } from "react-router-dom";

const MobilehamburgerItem = ({
	isLoggedIn,
	handleLogout,
	setIsActive,
	isActive,
	isPreimum,
}) => {
	const navigate = useNavigate();
	return (
		<>
			<div
				style={{ cursor: "pointer" }}
				className="header-mobile-logo"
				onClick={() => setIsActive(!isActive)}
			>
				<img width={25} height={25} src={barIcon} alt="icon" />
			</div>

			<div
				className={`${isActive ? "hamburger-active" : "hamburger-display"} `}
			>
				<ul>
					{/* <li>
            <p>iOS app</p>
          </li> */}

					{!isLoggedIn && (
						<li>
							<p
								onClick={() => {
									setIsActive(false);

									navigate("/signin");
								}}
							>
								Sign in{" "}
							</p>
						</li>
					)}
					<li className="d-none">
						<p
							onClick={() => {
								setIsActive(false);
								window.open("https://joinlft.com/contact", "_blank");
							}}
						>
							Contact
						</p>
					</li>
					<li className="d-none">
						<p
							onClick={() => {
								window.open("https://joinlft.com/faq", "_blank");
							}}
						>
							FAQ
						</p>
					</li>
					<li className="d-none">
						<p
							onClick={() => {
								setIsActive(false);
								window.open("https://joinlft.com/", "_blank");
							}}
						>
							About
						</p>
					</li>
					{isLoggedIn && (
						<>
							<li>
								<p
									onClick={() => {
										setIsActive(false);
										navigate("/profile");
									}}
								>
									Profile
								</p>
							</li>
							<li>
								<p
									onClick={() => {
										setIsActive(false);
										window.open(
											"https://docs.google.com/forms/d/e/1FAIpQLSeskAoXg8V3Mjn1xcGXRpnW7-tG7rsnAC4NFidKpqSLF-FmKw/viewform",
											"_blank"
										);
									}}
								>
									Feedback
								</p>
							</li>
							<li>
								<p onClick={handleLogout}>Logout</p>
							</li>
						</>
					)}
				</ul>
			</div>
		</>
	);
};

export default MobilehamburgerItem;
