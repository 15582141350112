import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { API_ROUTES } from "../config/apiRoutes";
import axiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";

const UnVerifiedModalBox = ({ isVerified = false }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user" || "{}"));
  const [openModal, setOpenModal] = useState(!user?.is_verified);

  const verifyOtp = async () => {
    const response = await axiosInstance.post(API_ROUTES.REQUEST_OTP, {
      email: user?.email,
    });
    if (response?.data) {
      localStorage.setItem("otp", JSON.stringify(response?.data.otp));
      navigate("/verify-otp");
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={handleClose}
        size="lg"
        className="news-feed-modal"
        centered
      >
        <Modal.Header style={{ justifyContent: "space-between" }}>
          <Modal.Title>Email not verified</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            Your Email is not Verified. Please verify your Email.
            <div className="site-input">
              <button className="site-btn" onClick={verifyOtp}>
                Verify
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UnVerifiedModalBox;
