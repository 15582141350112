import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showPremiumModal: false,
};

const premiumSlice = createSlice({
	name: "premium",
	initialState,
	reducers: {
		setShowPremiumModal(state, action) {
			const { payload = false } = action;
			state.showPremiumModal = payload;
		},
	},
});

export const { setShowPremiumModal } = premiumSlice.actions;
export default premiumSlice.reducer;
