import React, { useEffect, useCallback, useState } from "react";
import axiosInstance from "../axiosInstance";
import { API_ROUTES } from "../config/apiRoutes";
import moment from "moment";
import LoadingComponent from "../common/LoadingComponent";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Event = () => {
  const navigate = useNavigate();
  const { eventId } = useParams(); // Get eventId from URL params
  const [isLoading, setIsLoading] = useState(true);
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [event, setEvent] = useState(null);
  const [eventTitleBox, setEventTitleBox] = useState(null);
  const [videos, setVideos] = useState([]);
  const [activeTab, setActiveTab] = useState("news");

  const [localIsFollowed, setLocalIsFollowed] = useState(() => {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    return !!user?.accepted_events?.find((eve) => eve._id === eventId);
  });

  // Fetch event data
  useEffect(() => {
    const fetchEventData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.post("/news/get", {
          event_id: eventId,
        });

        if (response?.data) {
          setEvent(response.data.news);
          setEventTitleBox(response.data.event);
          setVideos(response.data.event.videos || []);
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
        navigate("/"); // Redirect to home on error
      } finally {
        setIsLoading(false);
      }
    };

    if (eventId) {
      fetchEventData();
    }
  }, [eventId]);

  const handleFollow = useCallback(
    async (eventId) => {
      setIsApiCalling(true);
      try {
        const response = await axiosInstance.post(
          localIsFollowed
            ? API_ROUTES.UN_FOLLOW_EVENT
            : API_ROUTES.FOLLOW_EVENT,
          { id: eventId }
        );

        if (response?.data?.user) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          setLocalIsFollowed(!localIsFollowed);
        }
      } catch (error) {
        console.error("Error following/unfollowing event:", error);
      } finally {
        setIsApiCalling(false);
      }
    },
    [eventId, localIsFollowed, navigate]
  );

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div className="event-page">
      <Helmet>
        <title>{eventTitleBox?.title || "lft - looking forward to"}</title>
        <meta
          name="title"
          content={eventTitleBox?.title || "lft - looking forward to"}
        />
        <meta
          name="description"
          content={
            eventTitleBox?.news_description ||
            "Discover and countdown to what's next in movies, shows, games, and more."
          }
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={eventTitleBox?.title || "lft - looking forward to"}
        />
        <meta
          property="og:description"
          content={
            eventTitleBox?.news_description ||
            "Discover and countdown to what's next in movies, shows, games, and more."
          }
        />

        {/* Twitter */}
        <meta
          property="twitter:title"
          content={eventTitleBox?.title || "lft - looking forward to"}
        />
        <meta
          property="twitter:description"
          content={
            eventTitleBox?.news_description ||
            "Discover and countdown to what's next in movies, shows, games, and more."
          }
        />
      </Helmet>
      <div className="event-top-header">
        <img
          src="../images/logo.png"
          alt="logo"
          width={114}
          height={50}
          style={{ cursor: "pointer" }}
        />
        <button
          onClick={() => window.close()}
          className="close-button"
          aria-label="Close"
        >
          ✕
        </button>
      </div>
      <div className="event-container">
        {/* Header Section */}
        <div className="event-header">
          <div
            className="modal-header-section align-items-center justify-content-between"
            style={{ paddingTop: "50px" }}
          >
            <div className="modal-header-title-date align-items-end">
              <h1>{eventTitleBox?.title}</h1>
              {eventTitleBox?.start_date &&
              moment(eventTitleBox.start_date).isValid() ? (
                <small style={{ paddingBottom: "13px", marginLeft: "10px" }}>
                  {moment(eventTitleBox?.start_date).format("MMMM D,YYYY")}
                </small>
              ) : (
                <small style={{ paddingBottom: "13px", marginLeft: "10px" }}>
                  {eventTitleBox?.no_date_title ||
                  eventTitleBox?.duration != null
                    ? String(
                        eventTitleBox?.no_date_title || eventTitleBox?.duration
                      )
                    : "Date/Duration Unavailable"}
                </small>
              )}
            </div>
            <div className="pe-4">
              {isApiCalling ? (
                <LoadingComponent />
              ) : (
                <button
                  className="update-event-follow-button"
                  onClick={() => handleFollow(eventId)}
                >
                  {localIsFollowed ? "UnFollow" : "Follow"}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Body Section */}
        <div className="event-body">
          {eventTitleBox?.news_description && (
            <h2
              style={{
                fontSize: "20px",
                color: "white",
                lineHeight: "23.8px",
                wordWrap: "break-word",
              }}
            >
              {eventTitleBox.news_description}
            </h2>
          )}

          {/* Tab Navigation */}
          <div className="newsfeed-tabs">
            <button
              className={activeTab === "news" ? "active" : ""}
              onClick={() => setActiveTab("news")}
            >
              Newsfeed
            </button>
            <button
              className={activeTab === "media" ? "active" : ""}
              onClick={() => setActiveTab("media")}
            >
              Media
            </button>
          </div>

          {/* Newsfeed Section */}
          {activeTab === "news" && (
            <>
              {event?.results?.length > 0 ? (
                <div className="newsfeed-body">
                  {event.results.map((newsItem) => (
                    <ul key={newsItem?._id}>
                      <li
                        onClick={() => window.open(newsItem?.url, "_blank")}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="in-left">
                          {newsItem?.image && (
                            <img
                              src={newsItem.image}
                              alt={newsItem.title}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.style.display = "none";
                              }}
                            />
                          )}
                        </div>
                        <div className="in-right">
                          <h5>{newsItem?.source?.title || "Unknown Source"}</h5>
                          <h3>{newsItem?.title}</h3>
                          <p className="line-clamp-2">{newsItem?.body}</p>
                          <span className="span-text">
                            {moment(newsItem?.dateTimePub).fromNow()}
                          </span>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              ) : (
                <p>No News Available</p>
              )}
            </>
          )}

          {/* Media Section */}
          {activeTab === "media" && (
            <>
              {videos?.length > 0 ? (
                <div className="video-section">
                  {videos
                    .map((videoUrl, index) => {
                      // Skip empty or invalid URLs
                      if (!videoUrl) return null;

                      try {
                        const url = new URL(videoUrl);
                        const videoId = url.searchParams.get("v");

                        if (!videoId) return null;

                        return (
                          <iframe
                            key={index}
                            width="100%"
                            height="315"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        );
                      } catch (error) {
                        return null;
                      }
                    })
                    .filter(Boolean)}{" "}
                  {/* Remove null values from rendered output */}
                </div>
              ) : (
                <p>No Videos Available</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Event;
