import React, { useMemo, useState } from "react";
import Tags from "./Tags";
import CardBottomText from "./CardBottomText";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { isPremiumUser } from "../helper/isPremium";
import FollowButton from "./FollowButton ";

const Card = React.forwardRef(
  (
    {
      handleClick,
      image,
      isTall = false,
      className = "",
      title = "",
      movieTitle = "",
      duration = "",
      followed = 0,
      eventId,
      isPrivate = false,
      setUserEvents,
      fetchPrivateEvents,
      isFilterEvent,
      isAuthenticated = false,
      isProfile,
      removeEvent,
      style,
    },
    ref
  ) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const storeUser = useSelector((state) => state.auth.user);

    const handleImageLoad = () => setIsImageLoaded(true);
    const isPremium = useMemo(() => isPremiumUser(storeUser), [storeUser]);

    return (
      <div
        ref={ref}
        style={style}
        className={`card-wrapper ${isTall ? "tall-card" : ""} ${className}`}
        onClick={() => handleClick && handleClick(eventId)}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 4,
            right: 8,
            top: 18,
            cursor: "pointer",
          }}
        >
          {/* Replace the old follow/unfollow implementation with FollowButton */}
          {isProfile && !isAuthenticated ? null : (
            <FollowButton
              eventId={eventId}
              isProfile={isProfile}
              isPrivate={isPrivate}
              removeEvent={removeEvent}
              setUserEvents={setUserEvents}
              fetchPrivateEvents={fetchPrivateEvents}
              isFilterEvent={isFilterEvent}
            />
          )}
        </div>
        <div
          className={`mesonate-image-container ${isTall ? "tall-image" : ""}`}
          style={{ position: "relative", overflow: "hidden" }}
        >
          <LazyLoad height={200} offset={100} style={{ height: "100%" }}>
            {!isImageLoaded && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  zIndex: 2,
                }}
              >
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "4px solid rgba(0, 0, 0, 0.1)",
                    borderTop: "4px solid #000",
                    borderRadius: "50%",
                    animation: "spin 1s linear infinite",
                  }}
                />
                <style>
                  {`
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                  `}
                </style>
              </div>
            )}
            <img
              src={image}
              alt={movieTitle}
              className="mesonate-image"
              onLoad={handleImageLoad}
              style={{
                width: "100%",
                height: "100%",
                opacity: isImageLoaded ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
              }}
            />
          </LazyLoad>
        </div>
        <Tags title={title} followed={followed} />
        <CardBottomText movieTitle={movieTitle} duration={duration} />
      </div>
    );
  }
);

export default Card;
