// import React, { useState } from 'react';
// import { Button, Modal } from 'react-bootstrap';

// const Footer = () => {
//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Button variant="primary" onClick={handleShow}>
//         Open Modal
//       </Button>

//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Modal Heading</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleClose}>
//             Save Changes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default Footer;

// import React from 'react';
// import { Tabs, Tab } from 'react-bootstrap';

// const Header = () => {
//   return (
// <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
//   <Tab eventKey="home" title="Home">
//     <p>Home Content</p>
//   </Tab>
//   <Tab eventKey="profile" title="Profile">
//     <p>Profile Content</p>
//   </Tab>
//   <Tab eventKey="contact" title="Contact">
//     <p>Contact Content</p>
//   </Tab>
// </Tabs>
//   );
// };

// export default Header;

// .adsbygoogle {
//   display: block;
//   width: 100%;
//   height: 200px; /* Adjust height as necessary */
// }

import { useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = ({ loggedIn = false, isPreimum }) => {
	useEffect(() => {
		// Load the Google Ads script dynamically
		const script = document.createElement("script");
		script.src =
			"https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2882562456421567";
		script.async = true;
		script.crossOrigin = "anonymous";
		document.body.appendChild(script);

		// Initialize the ads after the script is loaded
		script.onload = () => {
			setTimeout(() => {
				if (window.adsbygoogle && Array.isArray(window.adsbygoogle)) {
					window.adsbygoogle.push({});
				}
			}, 500);
		};

		return () => {
			// Cleanup the script on component unmount
			document.body.removeChild(script);
		};
	}, []);
	const footer_classes = `footer-height ${isPreimum ? "mb-0" : ""}`;
	return (
		<>
			<div className={footer_classes}>
				<hr />
				<div className="container main-footer-container">
					<div className="footer-text">
						<p className="my-3">
							<span className="clr-primary">lft - looking forward to</span> by
							Slowdown Digital
						</p>
						<p className="my-2 email-text">hello@slowdowndigital.com</p>
					</div>
					<div className="d-flex footer-btns">
						<div className="mx-3">
							<p className="my-2">
								<Link
									className="footer-link"
									target="_blank"
									to="https://joinlft.com/contact"
								>
									Feedback
								</Link>
							</p>
							{!loggedIn && (
								<p className="my-2">
									<Link className="footer-link" to="/signin">
										Sign in
									</Link>
								</p>
							)}
							{!loggedIn && (
								<p className="my-2">
									<Link className="footer-link" to="/signup">
										Sign up
									</Link>
								</p>
							)}
						</div>
						<div className="mx-3">
							<p className="my-2">
								<Link to="/privacy" className="footer-link">
									Privacy
								</Link>
							</p>
							<p className="my-2">
								<Link to="/tos" className="footer-link">
									ToS
								</Link>
							</p>
							{!isPreimum && (
								<p className="my-2">
									<Link className="footer-link">Premium</Link>
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
			{!isPreimum && (
				<footer className="site-footer">
					<div className="container">
						{/*<div className="footer-ads">
							<ins
								className="adsbygoogle"
								style={{ display: "block" }}
								data-ad-client="ca-pub-2882562456421567"
								data-ad-slot="7337904316"
								data-ad-format="auto"
								data-full-width-responsive="true"
							></ins>
						</div>*/}
					</div>
				</footer>
			)}
		</>
	);
};

export default Footer;
