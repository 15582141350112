import React from "react";

const CrownIcon = () => {
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<rect width="25" height="25" fill="url(#pattern0_4446_46)" />
			<defs>
				<pattern
					id="pattern0_4446_46"
					patternContentUnits="objectBoundingBox"
					width="1"
					height="1"
				>
					<use xlinkHref="#image0_4446_46" transform="scale(0.0111111)" />
				</pattern>
				<image
					id="image0_4446_46"
					width="90"
					height="90"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADh0lEQVR4nO2cP0hVURjAn2gZYhJu/aEpBScXG8MG21oFm0Np0IIass2xqKWhwcbIBtfGIiOqZzS8lsBora0Me2VQ0S8OnuAmej3nnu/cf+/7gSB6Pff7fu/63vd9h3sbDUVRFEVRFMUC9AIXgVXgm/0y38+Z3/07TgkAOAq8YXda5piQc3Q8bF3JaZKTsvXKzop9u3BlNvOJOh3glYfoZtHxVhag7SG6XXS8lQVY9xD9ueh4KwvwwkP086LjrSzAjIfo80XHW/XybtVBchPYX3S8lQY4sodsI/lw0XHWAmAA+L6DZPOzgaLjqw3AtZQrer7o+GoBcMiUbimivwCDRcdZeYAbDh+G1xtVpCxjSbYmd5sOojfznOCJ+CnTWBJYxJ3FnGIK91OmsSQwDPzyEG2OHY4ck4yfMo0lgWX8WY4ck4yfsowlgTHgTwbR5m/GIsYl46csY0ngMdl5GjEuGT+eC61HSuYM4UyUYGy7IfWvIT6WBLqA14Rj1ugqeGyb+tZh6kBXpiMkMokckxHim5b6MOy1pYkLz4BRwSR6gHfIYdbqEYxv1ObsQmvPsa0tyF1l/wbuSMwa8BvyuzIjENegzdHk6oJ7Q2deDXPp21lv26HU+gRcALozJtMHfEQes2Zfxpi6bU4mt70wjl5aZ9k3IIBzHq/mqQzrzxOPqxniOelRGEz5rp924tOeyT0EjguNQUNxHqOanRrgnmezNB4seNvcwRez+7EAHBAYg4aSOkYF9gGXTO2bYe0hSdH9AUm+B86m7AfutEUlzQ/g2C4xTABvA9buFxNtA/oamOwjYGTbmnfJj//GqMCJjIMrt+4vQLREjfsTuA0czDAGDcWUZyO2wlmwV3koazFEryDHB1s65k3TnluKlRiilwQDrAv3Y4i+VXRWJeRmDNFXis6qhFyOIdq1O+wkpmKI9u0OO4HxGKKzdId1ZyiG6JDusK7IdoWC3WGdkO8KE6Ild0CqzlpM0ZLdYdV5ElP0g6KzKxFLMUVrdxizK0yI1u4wZleYEK3dYcyuMCFau8OYXWFCtHaHMbvChGjtDmN3hYqiKIqiKPmSKG86moaKzgcVnRMqOidUdE6o6BqJ9rm7tq5s5CHa5+7autLMQ7TPoxTqymweol0fDlJXWrk9zNDceNOhslu5P33dXtlzibtr60pb5K5YRVEURVEURVEURVEURVGURkfzF0gMyKONeygfAAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	);
};

export default CrownIcon;
