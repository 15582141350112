import React, { useMemo, useState } from "react";
import { API_ROUTES } from "../config/apiRoutes";
import axiosInstance from "../axiosInstance";
import placeholder from "../images/placeholder.png";
import toast from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import InputField from "../common/InputField";
import TextArea from "../common/TextArea";
import { updateUserSchema } from "../validation/yupValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { isBoolean } from "lodash";
import Loading from "../common/Loading";

const Setting = () => {
	const user = JSON.parse(localStorage.getItem("user" || "{}"));
	const isPublic = useMemo(() => user?.is_public, [user?.is_public]);
	const avatar = useMemo(() => user?.avatar, [user?.avatar]);
	const [imagePreview, setImagePreview] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(updateUserSchema),
		mode: "onSubmit",
	});

	const handleFileChange = async (event) => {
		if (event.shiftKey) {
			return;
		}
		const validTypes = ["image/jpeg", "image/jpg", "image/png"];

		const file = event.target.files[0];
		if (!validTypes.includes(file.type)) return;

		if (file && file.size > 10 * 1024 * 1024) return;

		setIsLoading(true);
		let formData = new FormData();

		formData.append("avatar", file);
		const response = await axiosInstance.post(
			API_ROUTES.UPDATE_AVATAR,
			formData
		);
		if (response) {
			const user = response.data.user;
			const fileURL = URL.createObjectURL(file);
			setImagePreview(fileURL || user?.avatar);
			localStorage.setItem("user", JSON.stringify(user));
			toast.success("Avatar updated successfully");
		}
		setIsLoading(false);
	};

	const updateUser = async (updatedData) => {
		let payload = isBoolean(updatedData) ? {} : updatedData;
		if (isBoolean(updatedData)) {
			payload.is_public = updatedData.toString();
		}
		setIsLoading(true);

		const response = await axiosInstance.post(API_ROUTES.UPDATE_USER, payload);
		if (response) {
			const user = response?.data.user;
			localStorage.setItem("user", JSON.stringify(user));
			toast.success("Updated successfully");
			if (user && user.username) {
				navigate(`/${user.username}`);
			}
			// setTimeout(() =>{
			//   console.log('response--', user.username);
			//   navigate(`/${user.username}`);
			// }, 2000);
		}
		setIsLoading(false);
	};
	const onSubmit = async (data) => {
		const updatedData = {};
		const { name, username, bio, isPublic } = data;
		// const [first_name, last_name] = name.split(" ");
		updatedData.full_name = name;
		// if (name !== `${user?.first_name} ${user?.last_name}`) {
		//   updatedData.first_name = first_name;
		//   updatedData.last_name = last_name || "";
		// }
		if (username?.trim() !== user?.username?.trim()) {
			updatedData.username = username;
		}
		if (bio?.trim() !== user?.bio?.trim()) {
			updatedData.bio = bio;
		}
		if (isPublic !== user?.is_public) {
			updatedData.is_public = isPublic;
		}

		await updateUser(updatedData);
	};

	const handleInputChange = (field, event) => {
		if (event.shiftKey) {
			return;
		}
		if (event.type === "change") {
			field.onChange(event);
		}

		if (event.type === "keydown" && event.key === "Enter") {
			event.preventDefault();
			handleSubmit(onSubmit)();
		}
	};
	const handleCheckBox = async (field, event) => {
		try {
			event.preventDefault();
		} catch (e) { }
		if (event.shiftKey) {
			return;
		}
		field.onChange(event);
		await updateUser(event.target.checked);
	};

	return (
		<>
			{isLoading && <Loading />}

			<div>
				<div className="user-profile-meta user-profile-meta-with-padding mb-4">
					<div className="in-left">
						<img
							src={avatar ? avatar : imagePreview ? imagePreview : placeholder}
							alt="img"
						/>
					</div>
					<div className="in-right d-flex align-items-center justify-content-between">
						<div>
							<h4>Profile picture</h4>
							<p>JPG, PNG, under 10mb</p>
						</div>
						<div className="upload-custom-btn">
							<label className="m-0">
								<input
									type="file"
									className="d-none"
									accept="image/jpeg, image/png"
									onChange={handleFileChange}
								/>
								<span>Upload</span>
							</label>
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="user-profile-meta user-profile-meta-with-padding mb-4">
						<div className="w-100">
							<div className="site-input">
								<Controller
									name="name"
									control={control}
									defaultValue={`${user?.full_name}`}
									render={({ field }) => (
										<InputField
											placeholder={"Full Name"}
											type="text"
											errors={errors.name}
											{...field}
											ref={field.ref}
											onChange={(e) => handleInputChange(field, e)}
											onKeyDown={(e) => handleInputChange(field, e)}
										/>
									)}
								/>
							</div>
							<div className="site-input">
								<div className="position-relative">
									<Controller
										name="bio"
										control={control}
										defaultValue={user?.bio}
										render={({ field }) => (
											<TextArea
												placeholder={"Here’s what I’m looking forward to!"}
												type="text"
												errors={errors.bio}
												{...field}
												onChange={(e) => handleInputChange(field, e)}
												onKeyDown={(e) => handleInputChange(field, e)}
												ref={field.ref}
											/>
										)}
									/>
									<div className="textarea-counter">0/120</div>
								</div>
							</div>
							<div className="site-input input-left-text">
								<Controller
									name="username"
									control={control}
									defaultValue={user?.username}
									render={({ field }) => (
										<InputField
											placeholder={"name"}
											errors={errors.username}
											type="text"
											{...field}
											onChange={(e) => handleInputChange(field, e)}
											onKeyDown={(e) => handleInputChange(field, e)}
											ref={field.ref}
										/>
									)}
								/>
								<div className="left-text">lf.to/</div>
							</div>
							{/* <div
                  className="site-input text text-center"
                  style={{ marginBlock: "0px" }}
                >
                  <button
                    className="site-btn"
                    type="submit"
                    disabled={isUpdating}
                  >
                    {isUpdating && <LoadingComponent />} <span>Update</span>
                  </button>
                </div> */}
							<div className="text text-center">
								Press enter to save changes
							</div>
						</div>
					</div>
				</form>
				<div className="user-profile-meta user-profile-meta-with-padding justify-content-between">
					<div className="h4">Public page</div>
					<label className="custom-switch">
						<Controller
							name="isPublic"
							control={control}
							defaultValue={isPublic}
							render={({ field }) => (
								<input
									type="checkbox"
									checked={field.value}
									onChange={(event) => handleCheckBox(field, event)}
									ref={field.ref}
								// onChange={(e) => handleInputChange(e, "isPublic")}
								/>
							)}
						/>
						{/* <input type="checkbox" /> */}
						<span className="slider"></span>
					</label>
				</div>
			</div>
		</>
	);
};

export default Setting;
