import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axiosInstance from "../axiosInstance";
import { API_ROUTES } from "../config/apiRoutes";
import Card from "../common/Card";
// import { setUserData } from "../slices/auth-slice";
// import { setShowPremiumModal } from "../slices/premium-slice";
// import { isPremiumUser } from "../helper/isPremium";
// import Loading from "../common/Loading";
// import AddIcon from "../common/svgs/AddIcon";
// import CancelIcon from "../common/svgs/CancelIcon";
// import Tags from "../common/Tags";
// import CardBottomText from "../common/CardBottomText";
// import LazyLoad from "react-lazyload";

const DRAG_TYPE = "EVENT_CARD";

const DraggableEventCard = ({
  userEvent,
  index,
  moveEvent,
  fetchEventNews,
  setEventTitleBox,
  isFilterEvent,
  isAuthenticated,
  isProfile,
  setUserEvents,
  saveReorder,
  removeEvent,
  handleFollow,
}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: DRAG_TYPE,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveEvent(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
    drop: saveReorder,
  });

  const [{ isDragging }, drag] = useDrag({
    type: DRAG_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const handleClick = () => {
    if (userEvent.search_query?.length > 0) {
      // navigate(`/event/${userEvent._id}/news`);
      fetchEventNews(userEvent._id);
      setEventTitleBox({
        title: userEvent?.title,
        start_date: userEvent?.start_date,
        duration: userEvent?.duration?.displayDate,
        news_description: userEvent?.news_description,
      });
    }
  };

  return (
    <Card
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: "move" }}
      key={userEvent?._id}
      eventId={userEvent?._id}
      title={userEvent?.category?.name}
      followed={userEvent?.popularity}
      movieTitle={userEvent?.title}
      duration={userEvent?.duration?.displayDate}
      image={
        userEvent?.cropped_image?.startsWith("sfo3")
          ? `https://${userEvent.cropped_image}`
          : userEvent?.cropped_image || userEvent?.full_image || ""
      }
      isTall={userEvent?.post_shape === 3}
      handleClick={handleClick}
      setUserEvents={setUserEvents}
      removeEvent={removeEvent}
      isFilterEvent={isFilterEvent}
      isAuthenticated={isAuthenticated}
      isProfile={isProfile}
      handleFollow={handleFollow}
      isFollowed={userEvent.isFollowed}
    />
  );
};

const FeedsList = ({
  events,
  setEventId,
  fetchPrivateEvents,
  setEventTitleBox,
  setEvent,
  setIsLoading,
  isProfile = false,
  isFilterEvent = true,
  removeEvent,
  drag = false,
  handleFollow,
}) => {
  const [userEvents, setUserEvents] = useState([]);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (Array.isArray(events)) {
      setUserEvents(events);
    }
  }, [events]);

  const fetchEventNews = async (eventId) => {
    setIsLoading(true);
    setEventId(eventId);
    try {
      const response = await axiosInstance.post(API_ROUTES.EVENT_NEWS, {
        event_id: eventId,
      });
      if (response) {
        setEvent(response.data?.news);
      }
    } catch (error) {
      console.error("Error fetching event news:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (userEvent) => {
    if (userEvent.search_query?.length > 0) {
      //2nd
      // navigate(`/event/${userEvent._id}`);
      window.open(`/event/${userEvent.title}`, "_blank");
      // fetchEventNews(userEvent._id);
      setEventTitleBox({
        title: userEvent?.title,
        start_date: userEvent?.start_date,
        duration: userEvent?.duration?.displayDate,
      });
    }
  };
  const moveEvent = (fromIndex, toIndex) => {
    if (drag) {
      setUserEvents((prevEvents) => {
        const updated = [...prevEvents];
        const [movedItem] = updated.splice(fromIndex, 1);
        updated.splice(toIndex, 0, movedItem);
        return updated;
      });
    }
  };
  const saveReorder = async () => {
    try {
      const order = userEvents.map((e, ind) => ({
        order: ind,
        event_id: e._id,
      }));
      await axiosInstance.post(API_ROUTES.SAVE_CUSTOM_EVENT_ORDER, { order });
    } catch (error) {
      console.error("Error saving event reorder:", error);
    }
  };

  if (!Array.isArray(userEvents) || userEvents.length === 0) return null;

  return drag ? (
    <DndProvider backend={HTML5Backend}>
      <ul className={isProfile ? "mesonate-grid-profile" : "mesonate-grid"}>
        {userEvents.map((userEvent, index) => (
          <DraggableEventCard
            key={userEvent._id}
            userEvent={userEvent}
            index={index}
            moveEvent={moveEvent}
            fetchEventNews={fetchEventNews}
            setEventTitleBox={setEventTitleBox}
            isFilterEvent={isFilterEvent}
            isAuthenticated={isAuthenticated}
            isProfile={isProfile}
            setUserEvents={setUserEvents}
            setIsLoading={setIsLoading}
            setEventId={setEventId}
            setEvent={setEvent}
            saveReorder={saveReorder}
            removeEvent={removeEvent}
            handleFollow={handleFollow}
          />
        ))}
      </ul>
    </DndProvider>
  ) : (
    <ul className={isProfile ? "mesonate-grid-profile" : "mesonate-grid"}>
      {userEvents.map((userEvent) => (
        <Card
          key={userEvent?._id}
          eventId={userEvent?._id}
          title={userEvent?.category?.name}
          followed={userEvent?.popularity}
          movieTitle={userEvent?.title}
          duration={userEvent?.duration?.displayDate}
          image={
            userEvent?.cropped_image?.startsWith("sfo3")
              ? `https://${userEvent.cropped_image}`
              : userEvent?.cropped_image || userEvent?.full_image || ""
          }
          isTall={userEvent?.post_shape === 3}
          handleClick={() => handleClick(userEvent)}
          setUserEvents={setUserEvents}
          fetchPrivateEvents={fetchPrivateEvents}
          isFilterEvent={isFilterEvent}
          isPrivate={userEvent?.is_private}
          isAuthenticated={isAuthenticated}
          isProfile={isProfile}
          removeEvent={removeEvent}
          handleFollow={handleFollow}
          isFollowed={userEvent.isFollowed}
        />
      ))}
    </ul>
  );
};

export default FeedsList;
