import React from "react";
// import { chunkArray } from "../helper";
import { images } from "../images";
// import  SvgIcon  from "../common/svgs/index";

const Categories = ({ categories, filterByCategory, selectedCategory }) => {
	const textData = {
		All: "📅",
		Upcoming: "📅",
		Movies: "🎥",
		Holidays: "🎁️",
		Sport: "🏟️",
		Lifestyle: "💫",
		Festivals: "🎉️",
		"TV Shows": "📺️",
		"Tech & Games": "🕹️",
		Music: "🎧️",
	};

	return categories?.map((category, index) => {
		const DynamicComponent = images[category?.iconName];
		// const DynamicComponent = SvgIcon[category?.iconName];
		return (
			<li key={index} onClick={() => filterByCategory(category?.name)}>
				<span
					className={`${
						selectedCategory === category.name ? "activeCategory" : "category"
					} tags`}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							gap: "6px",
							cursor: "pointer",
						}}
					>
						{DynamicComponent && (
							// <img
							//   src={DynamicComponent}
							//   alt={category?.iconName}
							//   width={30}
							//   height={30}
							// />
							<span>
								{" "}
								{textData[category?.name]} {category?.name}
							</span>
							// <DynamicComponent />
						)}
					</div>
				</span>
			</li>
		);
	});
};

export default Categories;
