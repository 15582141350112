import InputField from "../common/InputField";
import AppleImage from "../images/apple-icon.png";
import { Controller, useForm } from "react-hook-form";
import { signupSchema } from "../validation/yupValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES } from "../config/apiRoutes";
import toast from "react-hot-toast";
import axiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";

function Signup() {
  const navigate = useNavigate();

  const handleGoogleSuccess = async (credentialResponse) => {
    if (!credentialResponse || !credentialResponse.credential) {
      toast.error("Google sign-up failed.");
      return;
    }

    try {
      const response = await axiosInstance.post(API_ROUTES.LOGIN_GOOGLE, {
        token: credentialResponse.credential,
      });

      if (response?.data) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", response.data.token);
        toast.success("Welcome to LFT!");
        navigate("/");
      }
    } catch (error) {
      console.error(
        "Google Signup Error:",
        error.response?.data || error.message
      );
      toast.error("Failed to sign up with Google. Please try again.");
    }
  };

  // Apple Login Handlers
  const handleResponse = async (response) => {
    if (!response) {
      console.error("No response received from Apple Login");
      return;
    }

    console.log("Apple Login Response:", JSON.stringify(response, null, 2));
    const { authorization: { id_token: appleToken, code: appleCode } = {} } =
      response || {};

    if (!appleToken) {
      toast.error("Failed to retrieve Apple token.");
      return;
    }

    const payload = {
      id_token: appleToken,
      code: appleCode,
    };

    try {
      const apiResponse = await axiosInstance.post(
        API_ROUTES.LOGIN_APPLE,
        payload
      );
      if (apiResponse?.data) {
        localStorage.setItem("user", JSON.stringify(apiResponse.data.user));
        localStorage.setItem("token", apiResponse.data.token);
        toast.success("Apple Signup Successful!");
        navigate("/"); // Redirect to homepage or dashboard
      }
    } catch (error) {
      console.error(
        "Apple Signup Error:",
        error.response?.data || error.message
      );
      toast.error("Failed to sign up with Apple. Please try again.");
    }
  };

  const handleError = (error) => {
    console.error("Apple Login Error:", error);
    toast.error("Apple Login failed. Please try again.");
  };

  // Form handling
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    const payload = {
      // first_name: '',
      // last_name: '',
      full_name: data.full_name,
      username: data.username,
      email: data.email,
      password: data.password,
    };

    try {
      const publicEventsResponse = await axiosInstance.post(
        API_ROUTES.SIGN_UP,
        payload
      );
      if (publicEventsResponse?.data) {
        const response = await axiosInstance.post(API_ROUTES.REQUEST_OTP, {
          email: data.email,
        });
        toast.success(
          "User signed up successfully. Now please verify your email."
        );
        if (response?.data) {
          localStorage.setItem("otp", JSON.stringify(response.data.otp));
          localStorage.setItem("email", data.email);
          navigate("/verify-otp");
        }
      }
    } catch (error) {
      console.error("Signup Error:", error);
      toast.error("Failed to sign up. Please try again.");
    }
  };

  return (
    <div className="auth-scree-wrapper">
      <div className="auth-scree-inner">
        <div className="auth-main-box">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Sign up</h2>
            <div className="site-input">
              <Controller
                name="full_name"
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder="Full Name"
                    type="text"
                    {...field}
                    errors={errors.full_name}
                  />
                )}
              />
            </div>
            <div className="site-input input-left-text">
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder="Username"
                    type="text"
                    {...field}
                    errors={errors.username}
                  />
                )}
              />
              <div className="left-text">lf.to/</div>
            </div>
            <div className="site-input">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder="Email"
                    type="email"
                    {...field}
                    errors={errors.email}
                  />
                )}
              />
            </div>
            <div className="site-input">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder="Password"
                    type="password"
                    {...field}
                    PasswordIcon
                    errors={errors.password}
                  />
                )}
              />
            </div>
            <div className="site-input">
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <InputField
                    placeholder="Confirm Password"
                    type="password"
                    {...field}
                    PasswordIcon
                    errors={errors.confirmPassword}
                  />
                )}
              />
            </div>
            <div className="site-input">
              <button className="site-btn" type="submit">
                Continue
              </button>
            </div>
            <div className="site-input">
              <span className="or-gap">Or</span>
            </div>
            {/* Google Sign-up Button */}
            <div className="site-input">
              {/* Styled Button Wrapper */}
              <div className="third-party-btn google-btn">
                <img
                  src="https://developers.google.com/identity/images/g-logo.png"
                  alt="Google"
                  style={{ marginRight: "10px", height: "20px" }}
                />
                Sign up with Google
                {/* Overlay Google Login Button */}
                <div className="google-login-overlay">
                  <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={() =>
                      toast.error("Google login failed. Please try again.")
                    }
                    text="signin_with"
                  />
                </div>
              </div>
            </div>
            <AppleLogin
              clientId="com.lft.webapp.service"
              redirectURI="https://lft-app-85b35.web.app/"
              responseType="code id_token"
              responseMode="form_post"
              usePopup
              onSuccess={handleResponse}
              onError={handleError}
              className="third-party-btn"
              render={(props) => (
                <button onClick={props.onClick} className="third-party-btn">
                  <img
                    src={AppleImage}
                    alt="Apple"
                    style={{ marginRight: "10px" }}
                  />
                  Sign up with Apple
                </button>
              )}
            />
          </form>
        </div>
        <div className="anchor-link-bottom" style={{ cursor: "pointer" }}>
          Already have an account?{" "}
          <a className="text-underline" onClick={() => navigate("/signin")}>
            Sign in
          </a>
        </div>
      </div>
    </div>
  );
}

export default Signup;
