import React from "react";

const CardBottomText = ({ movieTitle, duration }) => {
  return (
    <div className="mesonate-bottom-text">
      <h4>{movieTitle}</h4>
      <p>{duration}</p>
    </div>
  );
};

export default CardBottomText;
