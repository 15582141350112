import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import InputField from "../common/InputField";
import { userSchema } from "../validation/yupValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingComponent from "../common/LoadingComponent";
import toast from "react-hot-toast";
import { API_ROUTES } from "../config/apiRoutes";
import axiosInstance from "../axiosInstance";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../common/Loading";
import {
  APP_URL,
  PLANS,
  PRICE_IDS,
  STRIPE_CALLBACK_MESSAGES,
} from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateSessionId, setUserData } from "../slices/auth-slice";
import moment from "moment";

const ProfileView = ({ isPreimum, show, setShow, planShow }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  //   const [show, setShow] = useState(false);
  const [cancelPopupShow, setCancelPopupShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [activeTab, setActiveTab] = useState("profile");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [subDetail, setSubDetail] = useState({
    type: "Monthy",
    renewAt: "Oct. 20",
    inDays: "3",
  });
  const {
    isAuthenticated,
    user: storeUser,
    updateSessionId,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const tab = searchParams.get("tab");
    const message = searchParams.get("message");
    const validaTabs = ["profile", "plan", "billing"];
    if (validaTabs.includes(tab)) {
      if (tab === "plan" && message) {
        setSearchParams("");
        if (message === STRIPE_CALLBACK_MESSAGES.payment_success) {
          toast.success("Subscribed successfully");
        } else if (message === STRIPE_CALLBACK_MESSAGES.payment_failed) {
          toast.error("Payment failed. Try Again.");
        }
        loadUserData();
      } else if (tab === "billing" && message) {
        setSearchParams("");
        if (message === STRIPE_CALLBACK_MESSAGES.pm_update_failed) {
          toast.error("Payment method updation failed. Try Again.");
        } else if (message === STRIPE_CALLBACK_MESSAGES.pm_updated) {
          updatePaymentMethod();
        }
      }
      setActiveTab(tab);
    }
    if (planShow) {
      setActiveTab("plan");
    }
    console.log("use effect link call");
    setShow(false);
    const info = JSON.parse(localStorage.getItem("user") || "{}");
    if (Object.keys(info).length > 0) {
      console.log("use effect link call if");
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && storeUser.premium_active) {
      const { stripe: stripeObj = {} } = storeUser;
      const { price_id = "", period_end } = stripeObj;
      setSelectedOption(price_id);
      if (period_end) {
        const expireAt = moment.unix(period_end);
        const expireStr = expireAt.format("MMM, DD");
        const now = moment();
        const inDays = expireAt.diff(now, "days") + 1;
        const type = Object.keys(PRICE_IDS).find(
          (key) => PRICE_IDS[key] === price_id
        );
        const obj = { type, renewAt: expireStr, inDays };
        setSubDetail(obj);
      }
    }
  }, [isAuthenticated, storeUser]);
  const loadUserData = async () => {
    try {
      setLoader(true);
      const res = await axiosInstance.post(API_ROUTES.GET_USER_DATA);
      const { data = {} } = res || {};
      if (data.user) {
        dispatch(setUserData(res.data.user));
        localStorage.setItem("user", JSON.stringify(res.data.user));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const handleClose = () => {
    // navigate("/");
    setShow(false);
  };
  const handleCancelClose = () => {
    setCancelPopupShow(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    mode: "onSubmit",
  });

  const handleInputChange = (field, event) => {
    if (event.type === "change") {
      field.onChange(event);
    }

    if (event.type === "keydown" && event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { email, currentPassword, newPassword } = data;
    const payload = { email, old_password: currentPassword };
    if (newPassword) {
      payload.new_password = newPassword;
    }
    const response = await axiosInstance.post(API_ROUTES.UPDATE_USER, {
      ...payload,
    });
    if (response) {
      localStorage.setItem("user", JSON.stringify(response?.data.user));
      toast.success("Updated successfully");
    }
    setIsLoading(false);
  };
  const deleteAccount = async () => {
    setIsDeleting(true);
    const response = await axiosInstance.post(API_ROUTES.DELETE_USER);
    if (response) {
      toast.success("Account delete successfully");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      navigate("/");
    }
    setIsDeleting(false);
  };

  const handleCancelSubscription = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.post(API_ROUTES.CANCEL_SUBSCRIPTION);
      await wait(3000); //wait for 3 second to update user data from webhook
      const userRes = await axiosInstance.post(API_ROUTES.GET_USER_DATA);
      localStorage.setItem("user", JSON.stringify(userRes?.data.user));
      dispatch(setUserData(userRes.data.user));
      setSelectedOption("");
      setActiveTab("plan");
      toast.success("Subscription canceled");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
      setShowCancelSubscriptionModal(false);
    }
  };
  const updateSubscription = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.post(
        API_ROUTES.UPDATE_SUBSCRIPTION,

        {
          price_id: selectedOption,
        }
      );
      const userRes = await axiosInstance.post(API_ROUTES.GET_USER_DATA);
      localStorage.setItem("user", JSON.stringify(userRes?.data.user));
      dispatch(setUserData(userRes.data.user));
      toast.success("Subscription updated");
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCheckout = () => {
    if (isAuthenticated) {
      const { stripe = {}, premium_active } = storeUser;
      const { price_id } = stripe;
      if (premium_active && price_id && selectedOption !== PRICE_IDS.lifetime) {
        //handle update
        updateSubscription();
      } else {
        //setShowCheckout(true);
        handleCreateSubsription();
      }
    } else {
      //   navigate("/signin?redirect=/profile/?tab=plan");
      toast.warning("Please login to continue");
      navigate("/signin");
    }
  };
  const handleCreateSubsription = async () => {
    try {
      setIsLoading(true);
      if (selectedOption) {
        const res = await axiosInstance.post(
          API_ROUTES.CREATE_SUBSCRIPTION_CHECKOUT,
          {
            price_id: selectedOption,
            // cancel_url: `${APP_URL}/profile?tab=plan&message=${STRIPE_CALLBACK_MESSAGES.payment_failed}`,
            // success_url: `${APP_URL}/profile?tab=plan&message=${STRIPE_CALLBACK_MESSAGES.payment_success}`,
            cancel_url: `${APP_URL}/?message=${STRIPE_CALLBACK_MESSAGES.payment_failed}`,
            success_url: `${APP_URL}/?message=${STRIPE_CALLBACK_MESSAGES.payment_success}`,
          }
        );
        const { url } = res.data;
        window.location.replace(url);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdatePaymentMethod = async (payment_method) => {
    try {
      setIsLoading(true);
      const res = await axiosInstance.post(API_ROUTES.UPDATE_SESSION_CHECKOUT, {
        // cancel_url: `${APP_URL}/profile?tab=billing&message=${STRIPE_CALLBACK_MESSAGES.pm_update_failed}`,
        // success_url: `${APP_URL}/profile?tab=billing&message=${STRIPE_CALLBACK_MESSAGES.pm_updated}`,
        cancel_url: `${APP_URL}/?message=${STRIPE_CALLBACK_MESSAGES.pm_update_failed}`,
        success_url: `${APP_URL}/?message=${STRIPE_CALLBACK_MESSAGES.pm_updated}`,
      });
      const { url, session } = res.data;
      dispatch(setUpdateSessionId(session.id));
      window.location.replace(url);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePaymentMethod = async () => {
    try {
      setLoader(true);
      await axiosInstance.post(API_ROUTES.COMPLETE_SESSION_UPDATE, {
        session_id: updateSessionId,
      });
      dispatch(setUpdateSessionId(""));
      loadUserData();
      toast.success("Payment method updated");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoader(false);
    }
  };
  const wait = (time = 1000) => {
    return new Promise((res, _rej) => {
      setTimeout(() => {
        res(true);
      }, time);
    });
  };
  const { stripe = {} } = user;
  const {
    price_id: currentPlan = "",
    last_four = "4242",
    expiry_month = "12",
    expiry_year = "25",
    reason = "",
  } = stripe;
  const { subscriber = "stripe", premium_active } = storeUser;
  const isLifeTimeSubscription =
    premium_active && currentPlan === PRICE_IDS.lifetime;
  const isExpired = !!reason;

  return (
    <div>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          className="news-feed-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {isLoading && <Loading />}

            <div className="settingtabs-wrapper">
              <Tabs
                id="ProfileTabs1"
                activeKey={activeTab}
                onSelect={(tab) => {
                  setActiveTab(tab);
                }}
              >
                {isAuthenticated && (
                  <Tab eventKey="profile" title="Profile">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="user-profile-meta mb-4">
                        <div className="w-100">
                          <div className="site-input">
                            <Controller
                              name="email"
                              defaultValue={user?.email}
                              control={control}
                              render={({ field }) => (
                                <InputField
                                  placeholder={"Email@email.com"}
                                  type="email"
                                  {...field}
                                  ref={field.ref}
                                  onChange={(e) => handleInputChange(field, e)}
                                  onKeyDown={(e) => handleInputChange(field, e)}
                                  errors={errors.email}
                                />
                              )}
                            />
                          </div>
                          <div className="site-input">
                            <Controller
                              name="currentPassword"
                              control={control}
                              render={({ field }) => (
                                <InputField
                                  placeholder={"Current password"}
                                  type="password"
                                  {...field}
                                  PasswordIcon={true}
                                  ref={field.ref}
                                  onChange={(e) => handleInputChange(field, e)}
                                  onKeyDown={(e) => handleInputChange(field, e)}
                                  errors={errors.currentPassword}
                                />
                              )}
                            />
                          </div>
                          <div className="site-input">
                            <Controller
                              name="newPassword"
                              control={control}
                              render={({ field }) => (
                                <InputField
                                  placeholder={"New password"}
                                  type="password"
                                  {...field}
                                  PasswordIcon={true}
                                  ref={field.ref}
                                  onChange={(e) => handleInputChange(field, e)}
                                  onKeyDown={(e) => handleInputChange(field, e)}
                                  errors={errors.newPassword}
                                />
                              )}
                            />
                          </div>
                          <div className="site-input">
                            <Controller
                              name="confirmPassword"
                              control={control}
                              render={({ field }) => (
                                <InputField
                                  placeholder={"Confirm new password"}
                                  type="password"
                                  {...field}
                                  PasswordIcon={true}
                                  ref={field.ref}
                                  onChange={(e) => handleInputChange(field, e)}
                                  onKeyDown={(e) => handleInputChange(field, e)}
                                  errors={errors.confirmPassword}
                                />
                              )}
                            />
                          </div>
                          <div className="text text-center">
                            Press enter to save changes
                          </div>
                          {/* <div
                      className="site-input text text-center"
                      style={{ marginBlock: "0px" }}
                    >
                      <button
                        className="site-btn"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading && <LoadingComponent />}
                        <span>Update</span>
                      </button>
                    </div> */}
                        </div>
                      </div>
                    </form>
                    <div style={{ textAlign: "center" }}>
                      <button
                        style={{
                          background: "transparent",
                          border: "none",
                        }}
                        className="text text-center clr-danger"
                        onClick={() => setCancelPopupShow(true)}
                      >
                        {/* {isDeleting && <LoadingComponent />} */}
                        Delete account
                      </button>
                    </div>
                  </Tab>
                )}
                {(isPreimum || isExpired) && (
                  <Tab eventKey="billing" title="Billing">
                    {subscriber === "stripe" ? (
                      <div className="billing-field">
                        {!isLifeTimeSubscription && (
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="outline-light btn-custom button-outline-custom"
                              size="sm"
                              onClick={() => {
                                setShowCancelSubscriptionModal(true);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        )}
                        {isLifeTimeSubscription ? (
                          <h4 className="mt-5">
                            You have subscribed for lifetime.
                          </h4>
                        ) : (
                          <Form.Group controlId="formInput">
                            <div className="main-field-box">
                              <div
                                className="postion-label"
                                style={{ textTransform: "capitalize" }}
                              >
                                {subDetail.type}
                              </div>
                              <Form.Control
                                type="text"
                                placeholder="Renews on Oct. 20 (in 3 days)"
                                value={`Renews on ${subDetail.renewAt} (in ${subDetail.inDays} days)`}
                                style={{ background: "" }}
                                className="field-input"
                              />
                            </div>
                          </Form.Group>
                        )}
                        <div className="d-flex justify-content-end ">
                          <Button
                            onClick={handleUpdatePaymentMethod}
                            variant="outline-light btn-custom button-outline-custom"
                            size="sm"
                          >
                            {loader ? (
                              <LoadingComponent width="16px" height="16px" />
                            ) : (
                              "Change"
                            )}
                          </Button>
                        </div>
                        <Form.Group controlId="formInput">
                          <div className="main-field-box">
                            <div className="postion-label">{last_four}</div>
                            <div className="d-flex justify-content-end mobile-field">
                              <Form.Control
                                type="text"
                                placeholder="03/30"
                                value={`${expiry_month}/${expiry_year}`}
                                className="field-input custom-width-field"
                              />
                              <Form.Control
                                placeholder="***"
                                value={"***"}
                                className="field-input custom-width-field"
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    ) : (
                      <div className="billing-field">
                        <p className="my-5 py-5 text-center">
                          Your subscription is mananged by <b>{subscriber}</b>.
                        </p>
                      </div>
                    )}
                  </Tab>
                )}

                <Tab eventKey="plan" title="Plan">
                  {subscriber === "stripe" && (
                    <h5 className="heading-plans-modal">
                      Remove all ads, follow unlimited events, drag to organize
                      and show your support for the platform.
                    </h5>
                  )}
                  {!isAuthenticated ||
                  subscriber === "" ||
                  subscriber === "stripe" ? (
                    <div className="custom-radio-btn">
                      <Form>
                        {PLANS.map((option) => (
                          <label
                            key={option.priceId}
                            className={`custom-radio ${
                              selectedOption === option.priceId
                                ? "selected"
                                : ""
                            }`}
                          >
                            <div className="option-text">
                              <span>{option.name}</span>
                              <span>{option.price}</span>
                            </div>

                            {option.discount && (
                              <span className="discount-badge">
                                {option.discount}
                              </span>
                            )}
                            <input
                              type="radio"
                              name="plan"
                              value={option.priceId}
                              checked={selectedOption === option.priceId}
                              onChange={() => setSelectedOption(option.priceId)}
                            />
                          </label>
                        ))}
                      </Form>
                      <button
                        onClick={handleCheckout}
                        type="button"
                        disabled={
                          (isPreimum && currentPlan === selectedOption) ||
                          isLoading ||
                          isLifeTimeSubscription ||
                          selectedOption === ""
                        }
                        className="welcome-modal-button m-0 mt-3 w-100"
                      >
                        {loader ? (
                          <LoadingComponent width="16px" height="16px" />
                        ) : isPreimum ? (
                          selectedOption === currentPlan ? (
                            "Current plan"
                          ) : (
                            "Change Plan"
                          )
                        ) : (
                          "Checkout"
                        )}
                      </button>
                    </div>
                  ) : (
                    <div className="billing-field">
                      <p className="my-5 py-5 text-center">
                        Your subscription is mananged by <b>{subscriber}</b>.
                      </p>
                    </div>
                  )}
                </Tab>
                {/* <Tab eventKey="Preferences" title="Preferences">
                <div className="user-profile-meta mb-4">
                  <div className="w-100">
                    <ul className="Preferences-list">
                      <li>
                        <span>Countdown format</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="14"
                          viewBox="0 0 8 14"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.37629 5.93768C7.56376 6.1252 7.66907 6.37951 7.66907 6.64468C7.66907 6.90984 7.56376 7.16415 7.37629 7.35168L1.71929 13.0087C1.62704 13.1042 1.5167 13.1804 1.39469 13.2328C1.27269 13.2852 1.14147 13.3128 1.00869 13.3139C0.87591 13.3151 0.744231 13.2898 0.621335 13.2395C0.498438 13.1892 0.386786 13.115 0.292893 13.0211C0.199 12.9272 0.124747 12.8155 0.0744663 12.6926C0.0241854 12.5697 -0.00111606 12.4381 3.77571e-05 12.3053C0.00119157 12.1725 0.0287779 12.0413 0.0811869 11.9193C0.133596 11.7973 0.209778 11.6869 0.305288 11.5947L5.25529 6.64468L0.305288 1.69468C0.12313 1.50607 0.0223355 1.25347 0.0246139 0.991276C0.0268924 0.729079 0.132061 0.478266 0.317469 0.292858C0.502877 0.10745 0.75369 0.00228024 1.01589 1.90735e-06C1.27808 -0.00227642 1.53069 0.0985174 1.71929 0.280676L7.37629 5.93768Z"
                            fill="white"
                          />
                        </svg>
                      </li>
                      <li>
                        <span>Email notifications (followed events only)</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="14"
                          viewBox="0 0 8 14"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.37629 5.93768C7.56376 6.1252 7.66907 6.37951 7.66907 6.64468C7.66907 6.90984 7.56376 7.16415 7.37629 7.35168L1.71929 13.0087C1.62704 13.1042 1.5167 13.1804 1.39469 13.2328C1.27269 13.2852 1.14147 13.3128 1.00869 13.3139C0.87591 13.3151 0.744231 13.2898 0.621335 13.2395C0.498438 13.1892 0.386786 13.115 0.292893 13.0211C0.199 12.9272 0.124747 12.8155 0.0744663 12.6926C0.0241854 12.5697 -0.00111606 12.4381 3.77571e-05 12.3053C0.00119157 12.1725 0.0287779 12.0413 0.0811869 11.9193C0.133596 11.7973 0.209778 11.6869 0.305288 11.5947L5.25529 6.64468L0.305288 1.69468C0.12313 1.50607 0.0223355 1.25347 0.0246139 0.991276C0.0268924 0.729079 0.132061 0.478266 0.317469 0.292858C0.502877 0.10745 0.75369 0.00228024 1.01589 1.90735e-06C1.27808 -0.00227642 1.53069 0.0985174 1.71929 0.280676L7.37629 5.93768Z"
                            fill="white"
                          />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="user-profile-meta mb-4">
                  <div className="w-100">
                    <ul className="setting-Preferences-list">
                      <li>
                        <span>Hours</span>
                        <input type="radio" name="name" />
                      </li>
                      <li>
                        <span>Days</span>
                        <input type="radio" name="name" />
                      </li>
                      <li>
                        <span>Months - Days</span>
                        <input type="radio" name="name" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="user-profile-meta mb-4">
                  <div className="w-100">
                    <ul className="setting-Preferences-list">
                      <li>
                        <span>None</span>
                        <input type="radio" name="name" />
                      </li>
                      <li>
                        <span>Same day</span>
                        <input type="radio" name="name" />
                      </li>
                      <li>
                        <span>Day before</span>
                        <input type="radio" name="name" />
                      </li>
                      <li>
                        <span>One week before</span>
                        <input type="radio" name="name" />
                      </li>
                      <li>
                        <span>One month before</span>
                        <input type="radio" name="name" />
                      </li>
                    </ul>
                  </div>
                </div>
              </Tab> */}
              </Tabs>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={cancelPopupShow}
        onHide={handleCancelClose}
        size="md"
        className="news-feed-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <h2 className="my-1"> Are you sure?</h2>
          <p className="my-1">This action is irreversible.</p>
          <button
            className="text text-center cancelButton mx-3"
            onClick={() => handleCancelClose()}
          >
            Cancel
          </button>
          <button
            className="text text-center confirmButton"
            onClick={() => deleteAccount()}
            disabled={isDeleting}
          >
            {isDeleting && <LoadingComponent />}
            Delete
          </button>
        </Modal.Body>
      </Modal>
      <Modal
        show={showCancelSubscriptionModal}
        onHide={() => {
          setShowCancelSubscriptionModal(false);
        }}
        size="md"
        className="news-feed-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <h2 className="my-1"> Are you sure?</h2>
          <p className="my-1">You can resubscribe anytime.</p>
          <button
            className="text text-center cancelButton mx-3"
            onClick={() => setShowCancelSubscriptionModal(false)}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            className="text text-center confirmButton"
            onClick={handleCancelSubscription}
            disabled={isLoading}
          >
            {isLoading ? <LoadingComponent /> : "Confirm"}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProfileView;
