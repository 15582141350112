import React, { useEffect, useState } from "react";
import { API_ROUTES } from "../config/apiRoutes";
import axiosInstance from "../axiosInstance";
import ModalBox from "../common/ModalBox";
import moment from "moment";

const NewsFeed = ({ handleClose, openNewsFeed }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState();
  const getNewsFeed = async () => {
    setIsLoading(true);
    const response = await axiosInstance.post(API_ROUTES.GET_ALL_NEWS);
    if (response?.data) {
      setEvents(response.data.news);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getNewsFeed();
  }, []);

  const close = () => {
    debugger;
    handleClose();
  };

  return (
    <ModalBox
      isLoading={isLoading}
      handleClose={close}
      eventId={openNewsFeed}
      Header={"NewsFeed"}
      Body={
        !events?.length ? (
          "No News Available"
        ) : (
          <div className="newsfeed-body">
            {events?.map((eve) => (
              <ul key={eve?._id}>
                <li
                  onClick={() => window.open(eve?.url, "_blank")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="in-left">
                    <img src={eve.image} alt="img" />
                  </div>
                  <div className="in-right">
                    <h5>{eve?.source?.title}</h5>
                    <h3>{eve?.title}</h3>
                    <p className="line-clamp-2">{eve?.body}</p>
                    <span className="span-text">
                      {moment(eve?.dateTimePub).fromNow()}
                    </span>
                  </div>
                </li>
              </ul>
            ))}
          </div>
        )
      }
    />
  );
};

export default NewsFeed;
